import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';
import { storeToRefs } from 'pinia';

export const useWithdrawalManualAgentMethods = () => {
  type ManualProvider = {
    id: number;
    currency: string;
    name: string;
  }

  const { public: { gamehubCdn }, } = useRuntimeConfig();
  const { selectedCurrency } = storeToRefs(useTransactionStore());
  const withdrawalManualProviders = ref<ManualProvider[]>([]);
  const isLoadingManualProviders = ref(false);
  const currentManualWithdrawalProvider = ref();

  const transformWithdrawalProviders = computed(() => {
    return withdrawalManualProviders.value.map((item) => {
      const id = item?.name?.toLowerCase();
      return {
        ...item,
        id,
        img: `${gamehubCdn}/payments/${id}.svg`
      };
    });
  });

  const getWithdrawalManualAgentProviders = async () => {
    try {
      isLoadingManualProviders.value = true;
      const res = await useApiAuthInstance(`/api/payment/manual-agents/withdrawal-providers?currency=${selectedCurrency.value}`);
      withdrawalManualProviders.value = res?.data;
    } catch (e) {
      console.log('Error getWithdrawalManualAgentProviders:', e);
    } finally {
      isLoadingManualProviders.value = false;
    }
  };

  return {
    getWithdrawalManualAgentProviders,
    transformWithdrawalProviders,
    isLoadingManualProviders,
    currentManualWithdrawalProvider,
    withdrawalManualProviders
  };
};