<template>
  <form class="form-deposit-crypto">
    <div class="form-deposit-crypto__wrapper">
      <div class="form-deposit-crypto__block">
        <!--        Balance-->
        <skeletor v-if="isLoadingSupportedCurrencies" class="form-deposit-crypto__skeletor" as="div" />

        <template v-else>
          <form-input-display
            v-click-outside="() => (isOpenBalanceSelector ? (isOpenBalanceSelector = false) : null)"
            :is-open="isOpenBalanceSelector"
            :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
            :value="balanceObject?.value || ''"
            :img-url="balanceObject?.mask"
            :is-mobile="isMobile"
            @click-selector="
              isMobile ? toggleBottomSheetBalanceSelector('open') : (isOpenBalanceSelector = !isOpenBalanceSelector)
            "
          >
            <template v-if="!isMobile" #header>
              <div class="header">
                <form-input-search
                  v-model:value="searchCurrency"
                  :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
                />
              </div>
            </template>

            <list-simple
              v-if="!isMobile"
              v-model:value="currentCurrencyId"
              :searchValue="searchCurrency"
              :list="currencyList"
              @selected="selectCurrency"
            />
          </form-input-display>

          <client-only v-if="isMobile">
            <layout-bottom-sheet ref="balanceSelector" @closed="isOpenBalanceSelector = false">
              <template #header>
                <span class="title">
                  {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
                </span>
                <div class="header">
                  <form-input-search
                    v-model:value="searchCurrency"
                    :placeholder="
                      getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)
                    "
                  />
                </div>
              </template>
              <list-simple
                v-model:value="currentCurrencyId"
                :searchValue="searchCurrency"
                :list="currencyList"
                @selected="selectCurrency"
              />
              <template #footer>
                <button-base type="gray" size="sm" @click="toggleBottomSheetBalanceSelector('close')">
                  {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
                </button-base>
              </template>
            </layout-bottom-sheet>
          </client-only>
        </template>

        <wallet-limit v-model:deposit-error="depositError" :selected-currency="selectedCurrency" :deposit-balance="0" />

        <template v-if="!depositError">
          <!--        Network-->
          <skeletor
            v-if="isLoadingSupportedCurrencies || isLoadingDepositMethodsForCurrentCurrency"
            class="form-deposit-crypto__skeletor"
            as="div"
          />

          <template v-else-if="fields?.length">
            <form-input-display
              v-click-outside="() => (isOpenNetworkSelector ? (isOpenNetworkSelector = false) : null)"
              :is-open="isOpenNetworkSelector"
              :is-mobile="isMobile"
              :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.label')"
              :value="
                selectedNetworkView ||
                getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.placeholder')
              "
              @click-selector="
                isMobile ? toggleBottomSheetNetworkSelector('open') : (isOpenNetworkSelector = !isOpenNetworkSelector)
              "
            >
              <ul v-if="!isMobile" class="network-list">
                <li
                  v-for="option in networkSelectOptions"
                  :key="option.value"
                  :class="[
                    'network-list__item',
                    { 'network-list__item_active': option.code === selectedNetworkData?.code },
                  ]"
                  @click="setNetwork(option.code)"
                >
                  {{ `${option.value}${networkPrefix(option.code)}` }}
                </li>
              </ul>
            </form-input-display>

            <client-only v-if="isMobile">
              <layout-bottom-sheet ref="networkSelector" @closed="isOpenNetworkSelector = false">
                <template #header>
                  <span class="title">
                    {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.label') }}
                  </span>
                </template>

                <ul class="network-list">
                  <li
                    v-for="option in networkSelectOptions"
                    :key="option.value"
                    :class="[
                      'network-list__item',
                      { 'network-list__item_active': option.code === selectedNetworkData?.code },
                    ]"
                    @click="setNetwork(option.code)"
                  >
                    {{ `${option.value}${networkPrefix(option.code)}` }}
                  </li>
                </ul>

                <template #footer>
                  <button-base type="gray" size="sm" @click="toggleBottomSheetNetworkSelector('close')">
                    {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
                  </button-base>
                </template>
              </layout-bottom-sheet>
            </client-only>
          </template>
        </template>
      </div>

      <template v-if="!depositError">
        <!-- Destination tag -->
        <div v-if="destinationTag" class="form-deposit-crypto__block form-deposit-crypto__block_destination">
          <form-input-copy
            key="destinationTag"
            name="destinationTag"
            :label="
              getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.destination_tag.label') || ''
            "
            :value="destinationTag"
            icon-id="copy"
            @qr-click="copyValueToClipboard(destinationTag, 'copyDestinationTagSuccess')"
            @input-click="copyValueToClipboard(destinationTag, 'copyDestinationTagSuccess')"
          />
          <div class="form-deposit-crypto__info">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.destinationInfo') }}
          </div>
        </div>

        <!--      Wallet address-->
        <skeletor
          v-if="isLoadingSupportedCurrencies || isLoadingDepositMethodsForCurrentCurrency || isLoadingDepositData"
          class="form-deposit-crypto__skeletor form-deposit-crypto__skeletor_address"
          as="div"
        />

        <div v-else class="form-deposit-crypto__block">
          <div class="form-deposit-crypto__block-copy">
            <wallet-crypto-address
              class="form-deposit-crypto__copy-address"
              :address="walletAddress || ''"
              :label="sendLabelTxt"
              :icon-id="isMobile ? 'scan' : 'copy'"
              :max-address-length="isMobile ? 37 : 25"
              @qr-click="
                isMobile
                  ? (isShowQrCode = !isShowQrCode)
                  : copyValueToClipboard(walletAddress as string, 'copyAddressSuccess')
              "
              @input-click="copyValueToClipboard(walletAddress as string, 'copyAddressSuccess')"
            />

            <div class="form-deposit-crypto__warning">{{ warningTxt }}</div>

            <div v-if="!isMobile || (isShowQrCode && qrLink)" class="qr-code">
              <atomic-qr-logowc :address="qrLink" />
            </div>
          </div>

          <button-black
            class="form-deposit-crypto__copy"
            @click="copyValueToClipboard(walletAddress as string, 'copyAddressSuccess')"
          >
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.copyAddress') }}
          </button-black>
        </div>

        <!--      WC pay-->
        <div v-if="showEvmCryptoPay && walletAddress" class="form-deposit-crypto__block">
          <crypto-evm-pay
            :currency="selectedCurrency || ''"
            :selectedNetwork="state.selectedNetwork || ''"
            :wallet-client="evmWalletClient"
            :hint="fieldHint"
            :pay-settings="paySettings"
            :wallet-address="walletAddress"
          />
        </div>

        <div v-if="showTronCryptoPay && walletAddress" class="form-deposit-crypto__block">
          <crypto-tron-pay
            :currency="selectedCurrency || ''"
            :selectedNetwork="state.selectedNetwork || ''"
            :hint="fieldHint"
            :pay-settings="paySettings"
            :wallet-address="walletAddress"
          />
        </div>
      </template>
    </div>

    <template v-if="!depositError">
      <!--    WC connect -->
      <div v-if="showEvmWalletConnect || showTronWalletConnect" class="form-deposit-crypto__wrapper">
        <crypto-evm-connect v-if="showEvmWalletConnect" @set-evm-client="setEvmWalletClient" />

        <crypto-tron-connect v-if="showTronWalletConnect" @set-is-connected="setTronIsConnected" />
      </div>

      <div class="form-deposit-crypto__content">
        <template v-if="cryptoDepositBonuses?.length">
          <atomic-divider />
          <wallet-bonuses crypto />
        </template>

        <bonus-deposit-code class="form-deposit-crypto__bonus" />
      </div>
    </template>
  </form>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';
  import debounce from 'lodash/debounce';
  import { storeToRefs } from 'pinia';
  import * as Sentry from '@sentry/nuxt';

  import { WC_SUPPORT_CHAIN_LIST, TRC20, EVM_ADD_NAMES, TRON_ADD_NAMES } from '@skeleton/consts/wallet-connect';
  import { PayMethod } from '@skeleton/consts/method';

  import type { IBonus, IPaymentField, IRequestDeposit } from '@skeleton/core/types';
  import type { WalletClient } from '@wagmi/core';

  interface INetworkOption {
    value: string;
    minDeposit: number;
    maxDeposit: number;
    code: string;
  }

  interface IState {
    selectedDepositAccount?: string;
    selectedNetwork?: string;
    bonusId?: string;
    networkFields?: { [key: string]: string | undefined | null };
  }

  interface Props {
    amountMin?: number;
    method: string;
    fields: IPaymentField[];
  }

  const props = defineProps<Props>();

  const CryptoEvmConnect = defineAsyncComponent(() => import('@skeleton/components/wallet/crypto/evm-connect.vue'));
  const CryptoEvmPay = defineAsyncComponent(() => import('@skeleton/components/wallet/crypto/evm-pay.vue'));

  const CryptoTronConnect = defineAsyncComponent(() => import('@skeleton/components/wallet/crypto/tron-connect.vue'));
  const CryptoTronPay = defineAsyncComponent(() => import('@skeleton/components/wallet/crypto/tron-pay.vue'));

  const {
    popupsData,
    defaultLocalePopupsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    alertsData,
    defaultLocaleAlertsData,
    layoutData,
    defaultLocaleLayoutData,
  } = storeToRefs(useGlobalStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { selectedDepositBonus } = storeToRefs(useBonusStore());
  const { showAlert } = useLayoutStore();
  const { cryptoDepositBonuses } = storeToRefs(useProjectWallet());
  const { formatBalance, getContent } = useProjectMethods();

  const {
    currencyList,
    supportedTransactionAccounts,
    balanceObject,
    currentCurrencyId,
    getDepositMethodsForCurrentCurrency,
    selectedCurrency,
    isLoadingSupportedCurrencies,
    isLoadingDepositMethodsForCurrentCurrency,
  } = useTransactionOperations({ method: props.method });

  const currentDepositMethod = reactive<Props>({
    amountMin: props.amountMin,
    method: props.method,
    fields: [],
  });

  const state = reactive<IState>({
    selectedDepositAccount: selectedCurrency?.value,
    selectedNetwork: undefined,
    bonusId: selectedDepositBonus.value?.id,
    networkFields: undefined,
  });

  const depositError = ref(false);
  const isOpenBalanceSelector = ref(false);
  const isOpenNetworkSelector = ref(false);

  const walletAddress = ref<`0x${string}`>();
  const evmWalletClient = ref<WalletClient | null>(null);
  const isConnectedTron = ref(false);
  const qrLink = ref('');
  const destinationTag = ref<string>();
  const isShowQrCode = ref(false);
  const balanceSelector = ref();
  const networkSelector = ref();
  const searchCurrency = ref('');
  const isLoadingDepositData = ref(false);

  const getAccountId = (): string => {
    const findAccount = supportedTransactionAccounts.value.find(account => account.id === currentCurrencyId.value);
    return findAccount?.id || '';
  };

  const networkSelectOptions = computed<INetworkOption[]>(() => {
    const select = currentDepositMethod.fields?.find((item: IPaymentField) => item.fieldType === 'select');

    if (!select?.options) {
      return [];
    }

    const sortedOptions = sortNetworkList(select?.options ?? []);

    return sortedOptions.map(option => ({
      value: option.name,
      minDeposit: option.minAmount,
      maxDeposit: option.maxAmount,
      code: option.id || `empty-network-${option.name}`,
    }));
  });

  const selectedNetworkData = computed(() =>
    networkSelectOptions.value?.find(option => option.code === state.selectedNetwork)
  );

  const showEvmWalletConnect = computed(
    () =>
      WC_SUPPORT_CHAIN_LIST.includes(state?.selectedNetwork || '') ||
      EVM_ADD_NAMES.includes(selectedNetworkData.value?.value || '')
  );

  const showEvmCryptoPay = computed(() => !!evmWalletClient.value && showEvmWalletConnect.value);

  const showTronWalletConnect = computed(
    () => state.selectedNetwork === TRC20 || TRON_ADD_NAMES.includes(selectedNetworkData.value?.value || '')
  );
  const showTronCryptoPay = computed(() => isConnectedTron.value && showTronWalletConnect.value);

  const depositValues = computed(() => {
    const selectedCurrencyVal = selectedCurrency?.value || '';
    const minDeposit = selectedNetworkData?.value?.minDeposit ?? currentDepositMethod.amountMin;
    const { currency, amount } = formatBalance(selectedCurrencyVal, minDeposit);

    return {
      currency,
      amount,
    };
  });

  const fieldHint = computed(() => ({
    message: `${getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minSum') || ''} ${depositValues.value.amount} ${depositValues.value.currency}`,
  }));

  const warningTxt = computed(() => {
    const content = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minCryptoSum');

    if (content) {
      return content
        .replace(/{currency}/g, depositValues.value.currency)
        .replace(/{amount}/g, depositValues.value.amount);
    }

    return content || '';
  });

  const sendLabelTxt = computed(() => {
    const content = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.addressInputLabel');

    const currency = selectedCurrency.value || '';
    let network = selectedNetworkData.value?.code || '';

    if (network.includes('empty-network-')) {
      network = network.replace('empty-network-', '').split(' ')[0];
    }

    return content ? content.replace('{currency}', `${currency} (${network})`) : '';
  });

  const paySettings = computed(() => ({
    minAmount: selectedNetworkData?.value?.minDeposit || 0,
    maxAmount: selectedNetworkData?.value?.maxDeposit || 0,
  }));

  const sendDepositData = async (): Promise<void> => {
    if (!currentCurrencyId.value || depositError.value) {
      return;
    }

    const { depositAccount } = useCoreWalletApi();
    state.bonusId = selectedDepositBonus.value?.id;

    let depositData: IRequestDeposit;

    try {
      isLoadingDepositData.value = true;

      depositData = {
        method: currentDepositMethod.method || '',
        fields: state.networkFields,
        currency: selectedCurrency.value || '',
        amount: currentDepositMethod.amountMin || 0,
        accountId: getAccountId(),
        redirectSuccessUrl: window.location.origin,
        redirectErrorUrl: window.location.origin,
        bonusId: state.bonusId,
        isBonusDecline: false,
      };

      const depositResponse = await depositAccount(depositData);

      walletAddress.value = depositResponse?.address;
      qrLink.value = depositResponse?.qrAddress;
      destinationTag.value = depositResponse?.destinationTag;
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        scope.setContext('Deposit crypto', {
          depositData,
          fields: depositData?.fields,
        });
        scope.captureException(e);
      });

      walletAddress.value = undefined;
      qrLink.value = '';
      destinationTag.value = '';
      showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
    } finally {
      isLoadingDepositData.value = false;
    }
  };

  const setDefaultNetwork = async (): Promise<void> => {
    if (networkSelectOptions.value?.length) {
      const networkValue = networkSelectOptions.value[0].code.includes('empty-network')
        ? null
        : networkSelectOptions.value[0].code;
      state.networkFields = { crypto_network: networkValue };
      state.selectedNetwork = networkSelectOptions.value[0].code;
    }
  };

  const setNetwork = async (code: string) => {
    walletAddress.value = undefined;
    state.selectedNetwork = code;
    isMobile.value ? toggleBottomSheetNetworkSelector('close') : (isOpenNetworkSelector.value = false);
    const networkValue = state.selectedNetwork?.includes('empty-network') ? null : state.selectedNetwork;
    state.networkFields = { crypto_network: networkValue };
    await sendDepositData();
  };

  const networkPrefix = (code: string) => (code && !code.includes('empty-network') ? ` (${code})` : '');

  const selectedNetworkView = computed(() => {
    if (!selectedNetworkData.value) return '';

    return `${selectedNetworkData.value.value}${networkPrefix(selectedNetworkData.value.code)}`;
  });

  const copyValueToClipboard = async (text: string, field: string) => {
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      showAlert(alertsData.value?.wallet?.[field] || defaultLocaleAlertsData.value?.wallet?.[field]);
    } catch (e) {
      console.error('Locale mode', text);
    }
  };

  const setEvmWalletClient = (walletClient: WalletClient) => {
    evmWalletClient.value = walletClient;
  };

  const setTronIsConnected = (isConnected: boolean) => {
    isConnectedTron.value = isConnected;
  };

  const toggleBottomSheetBalanceSelector = (methodName: 'open' | 'close') => {
    balanceSelector.value?.[methodName]?.();
    isOpenBalanceSelector.value = methodName === 'open';
    searchCurrency.value = '';
  };

  const toggleBottomSheetNetworkSelector = (methodName: 'open' | 'close') => {
    networkSelector.value?.[methodName]?.();
    isOpenNetworkSelector.value = methodName === 'open';
  };

  const debounceDeposit = debounce(
    async (newBonusValue: IBonus | undefined): Promise<void> => {
      if (newBonusValue?.id === state.bonusId) return;
      await sendDepositData();
    },
    1000,
    { leading: false }
  );

  const setMethodForCurrency = async () => {
    if (props.method === PayMethod.DEPOSIT_WALLET_DIRECT_METHOD) {
      const group = await getDepositMethodsForCurrentCurrency();
      const allMethods = group.flatMap(group => group.paymentMethods);
      const method = allMethods.find(item => item.method === PayMethod.DEPOSIT_WALLET_DIRECT_METHOD);

      if (method) {
        currentDepositMethod.amountMin = method.amountMin;
        currentDepositMethod.fields = method.fields;
        currentDepositMethod.method = method.method;
      }
    } else {
      currentDepositMethod.amountMin = props.amountMin;
      currentDepositMethod.fields = props.fields;
      currentDepositMethod.method = props.method;
    }
  };

  const selectCurrency = async (accountId: string) => {
    isMobile.value ? toggleBottomSheetBalanceSelector('close') : (isOpenBalanceSelector.value = false);
    currentCurrencyId.value = accountId;
    await setDefaultNetwork();
  };

  watch(
    () => selectedDepositBonus.value,
    (newValue: IBonus | undefined) => {
      debounceDeposit(newValue);
    }
  );

  watch([currentCurrencyId], async () => {
    destinationTag.value = undefined;
    await setMethodForCurrency();
    await setDefaultNetwork();
    await sendDepositData();
  });
</script>

<style src="~/assets/styles/components/form/deposit-crypto.scss" lang="scss" />
