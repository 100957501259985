<template>
  <form-deposit-confirmation
    v-if="showConfirmation"
    :redirect-url="redirectProviderUrl"
    :icon-currency="iconCurrency"
    :active-account="activeAccount"
    :main-currency-amount="mainCurrencyAmount.amount"
    :method="props.method"
    :labels-data="depositFormData"
    @on-click-back-btn="showConfirmation = false"
    @on-click-deposit-btn="confirmDeposit"
  />

  <form v-else class="form-deposit-manager">
    <!--    Balance-->
    <skeletor v-if="isLoadingSupportedCurrencies" class="form-deposit-manager__skeletor" as="div" />

    <template v-else>
      <form-input-display
        v-click-outside="() => (isOpenBalanceSelector ? (isOpenBalanceSelector = false) : null)"
        :is-open="isOpenBalanceSelector"
        :is-mobile="isMobile"
        :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.balanceLabel')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        @click-selector="isMobile ? toggleBalanceSelector('open') : (isOpenBalanceSelector = !isOpenBalanceSelector)"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>
        <list-simple
          v-if="!isMobile"
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="currencyList"
          @selected="selectCurrency"
        />
      </form-input-display>

      <client-only v-if="isMobile">
        <layout-bottom-sheet ref="balanceSelector">
          <template #header>
            <span class="title">
              {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
            </span>
            <div class="header">
              <form-input-search
                v-model:value="searchCurrency"
                :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
              />
            </div>
          </template>
          <list-simple
            v-model:value="currentCurrencyId"
            :searchValue="searchCurrency"
            :list="currencyList"
            @selected="selectCurrency"
          />
          <template #footer>
            <button-base type="gray" size="md" @click="toggleBalanceSelector('close')">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
            </button-base>
          </template>
        </layout-bottom-sheet>
      </client-only>
    </template>

    <wallet-limit
      v-model:limit-error="limitError"
      v-model:deposit-error="depositError"
      :selected-currency="selectedCurrency"
      :deposit-balance="amountValue"
    />

    <template v-if="!depositError">
      <skeletor
        v-if="isLoadingSupportedCurrencies || isLoadingDepositMethodsForCurrentCurrency"
        class="form-deposit-manager__skeletor"
        as="div"
      />

      <!--    Amount -->
      <form-input-number
        v-else
        v-model:value="amountValue"
        :hint="fieldDepositSumHint"
        :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.sumLabel') || ''"
        name="depositSum"
        :min="formatAmountMin.amount"
        :max="formatAmountMax.amount"
        :defaultValue="formatAmountMin.amount"
        :currency="defaultInputSum.currency"
      />

      <!--  First name -->
      <form-input-text
        v-if="showField(FormField.FIRST_NAME)"
        v-model:value="form.firstName"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.firstName.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.firstName.placeholder`)"
        :name="FormField.FIRST_NAME"
        :hint="setError(FormField.FIRST_NAME)"
      />

      <!--  Last name -->
      <form-input-text
        v-if="showField(FormField.LAST_NAME)"
        v-model:value="form.lastName"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.lastName.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.lastName.placeholder`)"
        :name="FormField.LAST_NAME"
        :hint="setError(FormField.LAST_NAME)"
      />

      <!--    Card holder name-->
      <form-input-text
        v-if="showField(FormField.PLAYER_NAME)"
        v-model:value="form.cardHolderName"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.card_holder.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.card_holder.placeholder`)"
        :name="FormField.PLAYER_NAME"
        :hint="setError(FormField.PLAYER_NAME)"
      />

      <!--    City-->
      <form-input-text
        v-if="showField(FormField.CITY)"
        v-model:value="form.city"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.city.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.city.placeholder`)"
        :name="FormField.CITY"
        :hint="setError(FormField.CITY)"
      />

      <!--    Post code-->
      <form-input-text
        v-if="showField(FormField.POSTAL_CODE)"
        v-model:value="form.postCode"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.postalCode.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.postalCode.placeholder`)"
        :name="FormField.POSTAL_CODE"
        :hint="setError(FormField.POSTAL_CODE)"
      />

      <!--    Address -->
      <form-input-text
        v-if="showField(FormField.ADDRESS)"
        v-model:value="form.address"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.address.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.address.placeholder`)"
        :name="FormField.ADDRESS"
        :hint="setError(FormField.ADDRESS)"
      />

      <!--    Email-->
      <form-input-text
        v-if="showField(FormField.PLAYER_EMAIL)"
        v-model:value="form.email"
        name="playerEmail"
        type="email"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.email.label`)"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.email.placeholder`)"
        :hint="setError(FormField.PLAYER_EMAIL)"
      />

      <!--    Country-->
      <form-input-display
        v-if="showField(FormField.COUNTRY)"
        v-click-outside="() => (isOpenCountrySelector ? (isOpenCountrySelector = false) : null)"
        :is-open="isOpenCountrySelector"
        :isMobile="isMobile"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.country.label`)"
        :value="countryName"
        :img-url="`${iconFlags}/${form.currentCountry.toLowerCase()}.svg`"
        @click-selector="isMobile ? toggleCountrySelector('open') : (isOpenCountrySelector = !isOpenCountrySelector)"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCountryField"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>
        <list-simple
          v-if="!isMobile"
          v-model:value="form.currentCountry"
          :searchValue="searchCountryField"
          :list="countries"
          @selected="isOpenCountrySelector = false"
        />
      </form-input-display>

      <client-only v-if="isMobile">
        <layout-bottom-sheet ref="countrySelector">
          <template #header>
            <div class="title">
              {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.country.label`) }}
            </div>
            <div class="header">
              <form-input-search
                v-model:value="searchCountryField"
                :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
              />
            </div>
          </template>
          <list-simple
            v-model:value="form.currentCountry"
            :searchValue="searchCountryField"
            :list="countries"
            @selected="toggleCountrySelector('close')"
          />
          <template #footer>
            <button-base type="gray" @click="toggleCountrySelector('close')">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
            </button-base>
          </template>
        </layout-bottom-sheet>
      </client-only>

      <!-- Phone -->
      <form-input-phone
        v-if="showField(FormField.PHONE)"
        key="phone"
        v-model:value="form.phone"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.phone.label') || ''"
        :name="FormField.PHONE"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.phone.placeholder') || ''"
        :hint="setError(FormField.PHONE)"
        @blur="v$.phone?.$touch()"
      />

      <!--    CPF number -->
      <form-input-text
        v-if="showField(FormField.IDENTITY_DOC_NUMBER)"
        v-model:value="form.identify_doc_number"
        name="identify_doc_number"
        type="number"
        :label="
          getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.identify_doc_number.label') || ''
        "
        :placeholder="
          getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.identify_doc_number.placeholder') ||
          ''
        "
        :hint="setError(FormField.IDENTITY_DOC_NUMBER)"
      />

      <!-- providers -->
      <list-transaction-providers
        v-if="transformProviders?.length"
        :transaction-providers="transformProviders"
        :selected-transaction-provider="selectedProvider"
        @update:selected-transaction-provider="updateDepositProvider"
      />

      <!-- Bonus -->
      <bonus-deposit-code />

      <!-- Deposit -->
      <button-base
        type="primary"
        size="md"
        :is-disabled="isDisabledDepositBtn"
        :is-processing="isLoading"
        @click.prevent="onClickDeposit"
      >
        {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.depositButton') }}
      </button-base>
    </template>
  </form>
</template>

<script lang="ts" setup>
  import { Skeletor } from 'vue-skeletor';
  import * as Sentry from '@sentry/nuxt';

  import { ModalName } from '@skeleton/consts/modals';
  import { PayMethod, FormField } from '@skeleton/consts/method';

  import type { IPaymentField, IPaymentProvider } from '@skeleton/core/types';
  import type { ITransactionProvider } from '@skeleton/types';

  const props = defineProps<{
    amountMax?: number;
    amountMin?: number;
    method: string;
    fields: IPaymentField[];
    changeMobileForm?: () => void;
    selectedProvider?: IPaymentProvider;
  }>();

  const emit = defineEmits(['update:selectedProvider', 'clickBackBtn']);

  const { iconUrl: iconFlags } = useDynamicIcon('flags');
  const { iconUrl: iconCurrency } = useDynamicIcon('currency');
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const profileStore = useProfileStore();
  const { isTelegramUser } = storeToRefs(profileStore);
  const { closeModalSync } = useModalStore();
  const { formatBalance, getContent, getFormRules, createValidationRules, getMainBalanceFormat } = useProjectMethods();

  const bonusStore = useBonusStore();
  const { selectedDepositBonus } = storeToRefs(bonusStore);

  const {
    popupsData,
    defaultLocalePopupsData,
    layoutData,
    defaultLocaleLayoutData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
  } = storeToRefs(useGlobalStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const {
    currencyList,
    supportedTransactionAccounts,
    balanceObject,
    currentCurrencyId,
    selectedCurrency,
    isLoadingSupportedCurrencies,
    isLoadingDepositMethodsForCurrentCurrency,
    getDepositMethodsForCurrentCurrency,
  } = useTransactionOperations({ method: props.method });

  const { limitsContent, defaultLimitsContent } = storeToRefs(useLimitsStore());

  const { firstProvider, providers } = usePaymentProviders({ method: props.method, fields: props.fields });

  const transformProviders = computed(() => {
    return providers.value.map(item => {
      return {
        ...item,
        img: `${gamehubCdn}/payments/${String(item?.id).toLowerCase()}.svg`,
      };
    });
  });

  const balanceSelector = ref();
  const countrySelector = ref();
  const isOpenBalanceSelector = ref(false);
  const isOpenCountrySelector = ref(false);
  const isLoading = ref(false);
  const showConfirmation = ref(false);
  const redirectProviderUrl = ref('');
  const searchCountryField = ref('');
  const searchCurrency = ref('');
  const limitError = ref(false);
  const depositError = ref(false);

  const amountMax = ref(props.amountMax);
  const amountMin = ref(props.amountMin);

  const form = reactive({
    cardHolderName: '',
    firstName: profileStore.profile?.firstName || '',
    lastName: profileStore.profile?.lastName || '',
    phone: '',
    city: '',
    postCode: '',
    address: '',
    currentCountry: '',
    email: profileStore.profile?.email || '',
    identify_doc_number: '',
  });

  const confirmDeposit = async () => {
    await navigateTo('/');
    emit('clickBackBtn');
    closeModalSync(ModalName.WALLET);
  };

  const isExistingField = (fieldName: string): boolean => {
    return !!props.fields?.find(item => item.key === fieldName);
  };

  const isMethod = (method: string) => props.method === method;

  const showCardholder = isMethod(PayMethod.BILLBLEND_DEPOSIT_CARD) && isExistingField(FormField.PLAYER_NAME);

  const isBkashNagadOrOpenBanking = isMethod(PayMethod.BKASHNAGAD_DEPOSIT) || isMethod(PayMethod.OPEN_BANKING_DEPOSIT);

  const showFirstName = isBkashNagadOrOpenBanking || isExistingField(FormField.FIRST_NAME);

  const showLastName = isBkashNagadOrOpenBanking || isExistingField(FormField.LAST_NAME);

  const showField = (fieldName: string) => {
    switch (fieldName) {
      case FormField.PLAYER_NAME:
        return showCardholder;
      case FormField.FIRST_NAME:
        return showFirstName;
      case FormField.LAST_NAME:
        return showLastName;
      default:
        return isExistingField(fieldName);
    }
  };

  const formatAmountMax = computed(() => formatBalance(selectedCurrency.value, amountMax.value));
  const formatAmountMin = computed(() => formatBalance(selectedCurrency.value, amountMin.value));
  const defaultInputSum = computed(() => formatBalance(selectedCurrency.value, amountMin.value));

  const activeAccount = computed(() => {
    return supportedTransactionAccounts.value?.find(account => account.id === currentCurrencyId.value);
  });

  const countryField = computed(() => {
    return props?.fields?.find(field => field.key === 'country');
  });

  const countries = computed(() => {
    const list = countryField.value?.options || [];

    return list.map(item => ({
      ...item,
      icon: `${iconFlags.value}/${item.id.toLowerCase()}.svg`,
    }));
  });

  const countryName = computed(() => {
    const country = countries.value.find(item => item.id === form.currentCountry);
    return country?.name || '';
  });

  const isDisabledDepositBtn = computed(() => {
    return isLoading.value || limitError.value;
  });

  const fieldDepositSumHint = computed(() => {
    if (limitError.value) {
      return {
        message: getContent(limitsContent.value, defaultLimitsContent.value, 'deposit.wallet.title'),
        variant: 'error',
      };
    }

    return {
      message: `${
        getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minSum') || ''
      } ${formatAmountMin.value.amount} ${formatAmountMin.value.currency}`,
      variant: '',
    };
  });

  const amountValue = ref(Number(defaultInputSum.value.amount));

  const fieldsForValidation = computed(() => {
    return [
      { name: 'firstName', isRequired: showFirstName },
      { name: 'lastName', isRequired: showLastName },
      { name: 'playerName', isRequired: showCardholder },
      { name: 'phone', isRequired: isExistingField(FormField.PHONE) },
      { name: 'city', isRequired: isExistingField(FormField.CITY) },
      { name: 'postalCode', isRequired: isExistingField(FormField.POSTAL_CODE) },
      { name: 'address', isRequired: isExistingField(FormField.ADDRESS) },
      { name: 'playerEmail', isRequired: isExistingField(FormField.PLAYER_EMAIL) },
      { name: 'country', isRequired: isExistingField(FormField.COUNTRY) },
      { name: 'identify_doc_number', isRequired: isExistingField(FormField.IDENTITY_DOC_NUMBER) },
    ];
  });

  const depositRules = createValidationRules(fieldsForValidation.value, true);
  const depositFormRules = getFormRules(depositRules);

  const selectCurrency = async (accountId: string) => {
    isMobile.value ? toggleBalanceSelector('close') : (isOpenBalanceSelector.value = false);
    currentCurrencyId.value = accountId;
  };

  const depositFormData = computed((): { [key: string]: string | undefined | null } => {
    const fields = {
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      playerName: showCardholder ? form.cardHolderName : `${form.lastName} ${form.firstName}`.trim(),
      phone: form.phone.trim(),
      city: form.city.trim(),
      postalCode: form.postCode.trim(),
      address: form.address.trim(),
      playerEmail: form.email.trim(),
      country: form.currentCountry.trim(),
      identify_doc_number: form.identify_doc_number.trim(),
    };

    return Object.entries(fields).reduce<Record<string, string | undefined | null>>((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  });

  const { v$, setError } = useFormValidation(depositFormRules, depositFormData);

  const mainCurrencyAmount = computed(() => {
    return getMainBalanceFormat(defaultInputSum.value.currency, Number(amountValue.value));
  });

  const onClickDeposit = async () => {
    v$.value.$reset();
    const validFormData = await v$.value.$validate();

    if (!validFormData) {
      return;
    }

    let successRedirect = '';
    let errorRedirect = '';
    let redirectUrl = '';

    if (isTelegramUser.value) {
      redirectUrl = profileStore.telegram.botUrl || useRuntimeConfig().public.botURL;
      successRedirect = redirectUrl;
      errorRedirect = redirectUrl;
    } else {
      redirectUrl = window.location.href;
      successRedirect = `${redirectUrl}?deposit-status=success`;
      errorRedirect = `${redirectUrl}?deposit-status=failing`;
    }

    const params = {
      method: props.method || '',
      currency: activeAccount.value?.currency || '',
      amount: mainCurrencyAmount.value.amount,
      accountId: activeAccount.value?.id || '',
      redirectSuccessUrl: successRedirect,
      redirectErrorUrl: errorRedirect,
      redirectUrl: redirectUrl,
      bonusId: selectedDepositBonus.value?.id,
      ...(props.selectedProvider?.endpoint || Object.keys(depositFormData.value).length > 0
        ? {
            fields: {
              ...depositFormData.value,
              ...(props.selectedProvider?.endpoint ? { endpointId: props.selectedProvider.endpoint } : {}),
            },
          }
        : {}),
      isBonusDecline: !!selectedDepositBonus.value?.id,
    };

    try {
      isLoading.value = true;
      const { depositAccount } = useCoreWalletApi();
      const depositResponse = await depositAccount(params);

      redirectProviderUrl.value = depositResponse?.action || '';
      showConfirmation.value = true;
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    } finally {
      isLoading.value = false;
    }
  };

  const toggleBalanceSelector = (methodName: 'open' | 'close') => {
    balanceSelector.value[methodName]();
    searchCurrency.value = '';
  };

  const toggleCountrySelector = (methodName: 'open' | 'close') => {
    countrySelector.value[methodName]();
    searchCountryField.value = '';
  };

  const updateDepositProvider = (provider: ITransactionProvider & { img: string }) => {
    const { img, ...providerWithoutImg } = provider;
    emit('update:selectedProvider', providerWithoutImg);
  };

  const setStartParams = () => {
    form.currentCountry = countryField.value?.value || '';
    if (!props.selectedProvider) emit('update:selectedProvider', firstProvider.value);
  };

  const setDepositParams = async () => {
    const group = await getDepositMethodsForCurrentCurrency();
    const allMethods = group.flatMap(group => group.paymentMethods);
    const method = allMethods.find(item => item.method === props.method);

    if (method) {
      amountMin.value = method.amountMin;
      amountMax.value = method.amountMax;
      amountValue.value = Number(method.amountMin);
    }
  };

  onBeforeMount(() => {
    setStartParams();
  });

  watch([currentCurrencyId], async () => {
    setStartParams();
    await setDepositParams();
  });
</script>

<style lang="scss" src="~/assets/styles/components/form/deposit-manager.scss" />
