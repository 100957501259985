import { arbitrum, avalanche, bsc, mainnet, polygon, type Chain } from '@wagmi/core/chains';

export const RELAY_WC_URL = 'wss://relay.walletconnect.com';
export const TRON_API = 'https://api.trongrid.io';
export const TronNetwork = {
  MAINNET: 'tron:0x2b6653dc',
};

export const TronWcMethod = {
  SIGN_TRANSACTION: 'tron_signTransaction',
  SIGN_MESSAGE: 'tron_signMessage',
};

export const TronContractAddress: { [key: string]: string } = {
  USDT: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
};

export const TRX_DECIMALS = 6;

export const explorerTronWalletIds = [
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
  '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
];

export const metadata = {
  name: 'Win Casino',
  description: 'Win Casino',
  url: 'https://win.casino',
  icons: ['https://wincity.games/img/logo.svg'],
};

export const TRC20 = 'TRC20';
export const TRX = 'TRX';

const ChainName: { [key: string]: string } = {
  ERC20: 'ERC20',
  BEP20: 'BEP20',
  POLYGON: 'POLYGON',
  ARB1: 'ARB1',
  AVAXC: 'AVAXC',
};

export const NativeCurrency: { [key: string]: string } = {
  ETH: 'ETH',
  BNB: 'BNB',
  MATIC: 'MATIC',
  ETH_ARB1: 'ETH (ARB1)',
  AVAX: 'AVAX',
};

export const WC_SUPPORT_CHAIN_LIST: string[] = Object.values(ChainName);
export const WC_SUPPORT_NATIVE_CURRENCY_LIST: string[] = Object.values(NativeCurrency);
export const EVM_ADD_NAMES = ['Avalanche', 'BNB chain', 'Polygon', 'Ethereum network', 'Arbitrum'];
export const TRON_ADD_NAMES = ['Tron network'];

export const WinCityNativeCurrencyChain: { [key: string]: Chain } = {
  [NativeCurrency.ETH]: mainnet,
  [NativeCurrency.BNB]: bsc,
  [NativeCurrency.MATIC]: polygon,
  [NativeCurrency.ETH_ARB1]: arbitrum,
  [NativeCurrency.AVAX]: avalanche,
};

export const WinCityEvmChain: {
  [key: string]: Chain;
} = {
  [ChainName.ERC20]: mainnet,
  [ChainName.BEP20]: bsc,
  [ChainName.POLYGON]: polygon,
  [ChainName.ARB1]: arbitrum,
  [ChainName.AVAXC]: avalanche,
};
