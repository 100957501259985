import type { IPaymentField } from '@skeleton/core/types';
import type { ITransactionProvider } from '@skeleton/types';

export const usePaymentProviders = (props: { fields: IPaymentField[]; method: string }) => {
  const providers = ref<ITransactionProvider[]>([]);
  const firstProvider = ref<ITransactionProvider>();
  const transactionType = props.method.includes('.deposit') ? 'deposit' : 'withdrawal';

  const setProviders = () => {
    try {
      const labelField = props.fields?.filter(field => field.key === 'endpointId');

      if (!labelField?.length) {
        providers.value = [];
        firstProvider.value = undefined;
        return;
      }

      const [labelEndpoints] = labelField;
      const { depositEndpoints, withdrawalEndpoints } = labelEndpoints.labels;
      const transaction = transactionType === 'deposit' ? depositEndpoints : withdrawalEndpoints;
      const [provider] = transaction;
      providers.value = transaction;
      firstProvider.value = provider;
    } catch (e) {
      console.log('Error setProviders', e);
      providers.value = [];
      firstProvider.value = undefined;
    }
  };

  watch(
    () => props.method,
    () => {
      setProviders();
    },
    { immediate: true }
  );

  return {
    providers,
    firstProvider,
  };
};
