<template>
  <vue-final-modal
    v-model="modalsSync[ModalName.WALLET]"
    class="wallet-modal"
    :clickToClose="false"
    :focus-trap="false"
  >
    <div class="wallet-modal__container" :class="{ 'wallet-modal__container_show-form': showMobileForm }">
      <!-- Left  -->
      <div class="wallet-modal__slot-left">
        <!-- Header Left -->
        <div class="wallet-modal__header wallet-modal__header_left">
          <div class="wallet-modal__title">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.balanceLabel') }}
          </div>
          <button class="wallet-modal__close-btn wallet-modal__close-btn_right" @click="closeWallet">
            <atomic-icon id="close-dark" />
          </button>
        </div>

        <!-- Tabs -->
        <div class="wallet-modal__tabs">
          <div class="wallet-modal__tab-wrapper">
            <button
              v-for="tab in tabs"
              :key="tab"
              :class="['wallet-modal__tab', { 'wallet-modal__tab_active': tab === walletModalType }]"
              @click="selectTab(tab)"
            >
              {{ getContent(popupsData, defaultLocalePopupsData, `wallet.${tab}.title`) }}
            </button>
          </div>
        </div>

        <!-- Inner Left -->
        <div class="wallet-modal__slot-inner wallet-modal__slot-inner_left">
          <div v-if="isLoadingDataMethods" class="wallet-modal__skeletor-list">
            <skeletor v-for="i in 6" :key="i" class="wallet-modal__skeletor-item" as="div" />
          </div>

          <ul v-else-if="paymentGroups.length" class="wallet-modal__group-list">
            <li v-for="payMethodGroup in paymentGroups" :key="payMethodGroup.groupKey" class="wallet-modal__group-item">
              <form-input-payment
                v-model:active-provider="selectedProvider"
                v-model:active-payment-method="currentPayMethod"
                :payment-group="payMethodGroup"
                :balance-operation="walletModalType"
                @update:active-payment-method="showMobileForm = true"
              />
            </li>
          </ul>

          <atomic-empty-method
            v-else
            class="wallet-modal__empty-method"
            :text="getContent(popupsData, defaultLocalePopupsData, 'wallet.notAvailableText')"
          />
        </div>
      </div>

      <!-- Right  -->
      <div class="wallet-modal__slot-right">
        <!-- Header Right -->
        <div class="wallet-modal__header wallet-modal__header_right">
          <button class="wallet-modal__close-btn wallet-modal__close-btn_left" @click="handleClose">
            <atomic-icon id="back" />
          </button>
          <div class="wallet-modal__title">
            {{ `${getContent(popupsData, defaultLocalePopupsData, `wallet.${walletModalType}.title`)} ${modalTitle}` }}
          </div>
          <button class="wallet-modal__close-btn" @click="closeWallet">
            <atomic-icon id="close-dark" />
          </button>
        </div>

        <!-- Inner Right -->
        <div class="wallet-modal__slot-inner">
          <div v-if="!currentPayMethod" class="wallet-modal__empty">
            <div class="wallet-modal__empty-inner">
              <div class="wallet-modal__empty-header">
                {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.noPaymentMethodSelected') || '' }}
              </div>
              <div class="wallet-modal__empty-desc">
                {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.selectPaymentMethod') || '' }}
              </div>
            </div>
          </div>

          <template v-else-if="walletModalType === PayType.DEPOSIT">
            <form-deposit-manual-cash-agent
              v-if="currentPayMethod.method === PayMethod.MANUAL_AGENT_DEPOSIT"
              :key="currentPayMethod.method"
              v-bind="currentPayMethod"
            />

            <form-deposit-crypto
              v-else-if="currentPayMethod.method.includes(PayMethod.DEPOSIT_WALLET_DIRECT_METHOD)"
              :key="`${currentPayMethod.method}-crypto`"
              v-bind="currentPayMethod"
            />

            <form-deposit-cash-agent
              v-else-if="currentPayMethod.method === PayMethod.DEPOSIT_CASH_AGENT_METHOD"
              :key="PayMethod.DEPOSIT_CASH_AGENT_METHOD"
            />

            <form-deposit-crypto-bank
              v-else-if="currentPayMethod.method === PayMethod.CHANGELLY_DEPOSIT_CARD"
              :key="PayMethod.CHANGELLY_DEPOSIT_CARD"
              v-bind="currentPayMethod"
            />

            <form-deposit-manager
              v-else
              :key="`${currentPayMethod.method}-manager`"
              v-model:selectedProvider="selectedProvider"
              v-bind="currentPayMethod"
            />
          </template>

          <form-withdraw
            v-else-if="walletModalType === PayType.WITHDRAW"
            :key="currentPayMethod?.method"
            v-bind="currentPayMethod"
            :change-mobile-form="handleClose"
          />

          <div class="wallet-modal__problems">
            <button class="wallet-modal__problems-btn" @click="toSupport">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.anyProblems') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';
  import { VueFinalModal } from 'vue-final-modal';

  import type { IPaymentMethod, IPaymentProvider } from '@skeleton/core/types';
  import type { IProfileLimits } from '~/types';

  import { PayMethod, PayType } from '@skeleton/consts/method';
  import { ModalName } from '@skeleton/consts/modals';

  const tabs = Object.values(PayType);

  const router = useRouter();
  const { getLimits, setLimitsContent } = useLimitsStore();
  const { getContent, localizePath } = useProjectMethods();
  const modalStore = useModalStore();
  const { closeModalSync } = modalStore;
  const { modalsSync } = storeToRefs(modalStore);

  const transactionStore = useTransactionStore();

  const { walletModalType, withdrawMethods, depositMethods, isLoadingDataMethods } = storeToRefs(transactionStore);

  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const showMobileForm = ref(false);
  const selectedProvider = ref<IPaymentProvider>();
  const currentPayMethod = ref<IPaymentMethod>();

  const paymentGroups = computed(() => {
    return walletModalType.value === PayType.DEPOSIT ? depositMethods.value : withdrawMethods.value;
  });

  const modalTitle = computed(() => {
    const payMethod = currentPayMethod.value?.method || '';
    const isDeposit = walletModalType.value === PayType.DEPOSIT;
    const isWithdraw = walletModalType.value === PayType.WITHDRAW;

    if (isDeposit) {
      const depositMapping = {
        [PayMethod.DEPOSIT_CASH_AGENT_METHOD]: 'wallet.inCash',
        [PayMethod.CHANGELLY_DEPOSIT_CARD]: 'wallet.inCrypto',
        [PayMethod.DEPOSIT_WALLET_DIRECT_METHOD]: 'wallet.inCrypto',
      };
      return getContent(popupsData.value, defaultLocalePopupsData.value, depositMapping[payMethod]) || '';
    }

    if (isWithdraw) {
      const withdrawMapping = {
        [PayMethod.WITHDRAW_CASH_AGENT_METHOD]: 'wallet.toCash',
        [PayMethod.WITHDRAW_WALLET_DIRECT_METHOD]: 'wallet.toCrypto',
      };
      return getContent(popupsData.value, defaultLocalePopupsData.value, withdrawMapping[payMethod]) || '';
    }

    return '';
  });

  const mobileWidth = (): boolean => window.innerWidth < 768;

  const closeWallet = (): void => {
    showMobileForm.value = false;
    currentPayMethod.value = undefined;
    selectedProvider.value = undefined;
    closeModalSync(ModalName.WALLET);
  };

  const handleClose = (): void => {
    mobileWidth() ? (showMobileForm.value = false) : closeWallet();
  };

  const toSupport = async (): Promise<void> => {
    closeWallet();
    await router.push(localizePath('/support'));
  };

  const selectTab = async (tab: string) => {
    if (walletModalType.value === tab) return;

    walletModalType.value = tab;
    showMobileForm.value = false;
    currentPayMethod.value = undefined;
    selectedProvider.value = undefined;

    if (tab === PayType.DEPOSIT) {
      await transactionStore.getAllDepositMethods();
      await getLimits();
    }

    if (tab === PayType.WITHDRAW) {
      await transactionStore.getWithdrawMethods();
    }
  };

  const { getContentData } = useContentLogic<IProfileLimits>({
    contentKey: 'profileLimitsContent',
    contentRoute: ['profile', 'limits'],
    isPage: false,
  });
  const { data: limitContent } = await useLazyAsyncData(getContentData);

  watch(
    limitContent,
    () => {
      if (limitContent.value) {
        setLimitsContent(limitContent.value?.currentLocaleData, limitContent.value?.defaultLocaleData);
      }
    },
    { immediate: true }
  );
</script>

<style src="~/assets/styles/components/modal/wallet.scss" lang="scss" />
