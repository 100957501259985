<template>
  <div class="form-deposit-confirmation">
    <div class="form-deposit-confirmation__top">
      <div class="form-deposit-confirmation__title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.depositConfirmation.title') }}
      </div>
      <div class="form-deposit-confirmation__subtitle">
        {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.depositConfirmation.subTitle') }}
      </div>
    </div>

    <ul class="form-deposit-confirmation__list">
      <li v-for="item in items" :key="item.label" class="form-deposit-confirmation__item">
        <span class="form-deposit-confirmation__label">{{ item.label }}</span>
        <div class="form-deposit-confirmation__value">
          <atomic-image v-if="item.img" :src="item.img" />
          <span>{{ item.value }}</span>
        </div>
      </li>
    </ul>

    <div class="form-deposit-confirmation__bottom">
      <button-base class="form-deposit-confirmation__btn" type="gray" size="md" @click="emit('onClickBackBtn')">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.back') }}
      </button-base>

      <button-base
        class="form-deposit-confirmation__btn"
        type="primary"
        size="md"
        tag-name="a"
        :href="props.redirectUrl"
      >
        {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.deposit') }}
      </button-base>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { PayMethod } from '@skeleton/consts/method';

  import type { IAccount } from '@skeleton/core/types';

  const emit = defineEmits(['onClickBackBtn', 'onClickDepositBtn']);

  const props = defineProps<{
    redirectUrl: string;
    iconCurrency?: string;
    activeAccount?: IAccount;
    mainCurrencyAmount?: number;
    method: string;
    labelsData: {
      firstName?: string;
      lastName?: string;
      playerName?: string;
      address?: string;
      playerEmail?: string;
      providerName?: string;
      phone?: string;
      fiatDeposit?: {
        amount: string;
        currency: string;
        fee: string;
        amountExpected: string;
        cryptoCurrency: string;
      };
    };
  }>();

  const { getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData, popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const items = computed(() =>
    [
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.amount'),
        value: props.mainCurrencyAmount ? `${props.mainCurrencyAmount} ${props.activeAccount?.currency}` : '',
        img: `${props.iconCurrency}/${props.activeAccount?.currency}.svg`,
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.firstName'),
        value: props.labelsData?.firstName ? `${props.labelsData?.firstName}` : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.lastName'),
        value: props.labelsData?.lastName ? `${props.labelsData?.lastName}` : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.cardholderName'),
        value:
          props.labelsData?.playerName && props.method === PayMethod.BILLBLEND_DEPOSIT_CARD
            ? `${props.labelsData?.playerName}`
            : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.address'),
        value: props.labelsData?.address ? `${props.labelsData?.address}` : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.email'),
        value: props.labelsData?.playerEmail ? `${props.labelsData?.playerEmail}` : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.phone'),
        value: props.labelsData?.phone ? `${props.labelsData?.phone}` : '',
      },
      {
        label: getContent(
          popupsData.value,
          defaultLocalePopupsData.value,
          'wallet.depositConfirmation.selectedProvider'
        ),
        value: props.labelsData?.providerName ? `${props.labelsData?.providerName}` : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.depositConfirmation.amount'),
        value: props.labelsData.fiatDeposit?.amount
          ? `${props.labelsData.fiatDeposit.amount} ${props.labelsData.fiatDeposit.currency}`
          : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.fee'),
        value: props.labelsData.fiatDeposit?.fee
          ? `-${props.labelsData.fiatDeposit.fee} ${props.labelsData.fiatDeposit.currency}`
          : '',
      },
      {
        label: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.youWillGet'),
        value: props.labelsData.fiatDeposit?.amountExpected
          ? `${props.labelsData.fiatDeposit.amountExpected} ${props.labelsData.fiatDeposit.cryptoCurrency}`
          : '',
      },
    ].filter(item => !!item.value)
  );
</script>

<style src="~/assets/styles/components/form/deposit-confirmation.scss" lang="scss" />
