<template>
  <form class="form-withdraw">
    <!--    Balance-->
    <skeletor v-if="isLoadingSupportedCurrencies" class="form-deposit-crypto__skeletor" as="div" />

    <div v-else class="form-withdraw__balance">
      <form-input-display
        v-click-outside="() => (isOpenBalanceSelector ? (isOpenBalanceSelector = false) : null)"
        :isMobile="isMobile"
        :is-open="isOpenBalanceSelector"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        @click-selector="isMobile ? toggleBalanceSelector('open') : (isOpenBalanceSelector = !isOpenBalanceSelector)"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>
        <list-simple
          v-if="!isMobile"
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="filteredCurrencyList || []"
          @selected="selectCurrency"
        />
      </form-input-display>

      <div class="form-withdraw__info">
        <div>{{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.info') }}</div>
        <div>
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.bonusValue') }}:
          <span>{{ `${bonusBalance?.bonusBalance} ${bonusBalance?.currency}` }}</span>
        </div>
      </div>
    </div>

    <client-only>
      <layout-bottom-sheet ref="balanceSelector" @closed="isOpenBalanceSelector = false">
        <template #header>
          <span class="title">
            {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
          </span>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>
        <list-simple
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="filteredCurrencyList || []"
          @selected="selectCurrency"
        />
        <template #footer>
          <button-base type="gray" size="sm" @click="toggleBalanceSelector('close')">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
          </button-base>
        </template>
      </layout-bottom-sheet>
    </client-only>

    <!--        Network-->
    <template v-if="networkSelectOptions?.length">
      <form-input-display
        v-click-outside="() => (isOpenNetworkSelector ? (isOpenNetworkSelector = false) : null)"
        :is-open="isOpenNetworkSelector"
        :is-mobile="isMobile"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.label')"
        :value="
          selectedNetworkData?.value ||
          getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.placeholder')
        "
        @click-selector="isMobile ? toggleNetworkSelector('open') : (isOpenNetworkSelector = !isOpenNetworkSelector)"
      >
        <ul v-if="!isMobile" class="network-list">
          <li
            v-for="option in networkSelectOptions"
            :key="option.value"
            :class="['network-list__item', { 'network-list__item_active': option.code === selectedNetworkData?.code }]"
            @click="setNetwork(option.code)"
          >
            {{ option.value }}
          </li>
        </ul>
      </form-input-display>

      <layout-bottom-sheet v-if="isMobile" ref="networkSelector" @closed="isOpenNetworkSelector = false">
        <template #header>
          <span class="title">
            {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.networkSelect.label') }}
          </span>
        </template>

        <ul class="network-list">
          <li
            v-for="option in networkSelectOptions"
            :key="option.value"
            :class="['network-list__item', { 'network-list__item_active': option.code === selectedNetworkData?.code }]"
            @click="setNetwork(option.code)"
          >
            {{ option.value }}
          </li>
        </ul>

        <template #footer>
          <button-base type="gray" size="sm" @click="toggleNetworkSelector('close')">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
          </button-base>
        </template>
      </layout-bottom-sheet>
    </template>

    <div class="form-withdraw__content">
      <form-input-number
        v-model:value="amountValue"
        :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.sumLabel') || ''"
        name="withdrawSum"
        :min="formatAmountMin.amount"
        :max="formatAmountMax.amount"
        :currency="formatAmountMin.currency"
        :hint="fieldHint"
      />

      <template v-for="field in visibleFields" :key="field.key">
        <component
          :is="fieldsTypeMap[field.key as keyof typeof fieldsTypeMap]?.component || 'form-input-text'"
          v-model:value="withdrawFormData[field.key]"
          :type="fieldsTypeMap[field.key as keyof typeof fieldsTypeMap]?.type || 'text'"
          :label="tryGetFieldName(field.key, 'label')"
          :name="field.key"
          :placeholder="tryGetFieldName(field.key, 'placeholder')"
          :options="getFieldOptions(field.key)"
          :isRequired="withdrawFormRules[field.key]?.hasOwnProperty('required')"
          :hint="setError(field.key)"
          @input="v$[field.key]?.$touch()"
          @blur="v$[field.key]?.$touch()"
          @focus="onFocus(field.key)"
        />

        <div v-if="field.key === 'pixKey'" class="form-withdraw__info form-withdraw__info_for-component">
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.pixInfo') }}
        </div>
      </template>
    </div>

    <list-transaction-providers
      v-if="withdrawalProviders.length"
      is-lite
      :transaction-providers="withdrawalProviders"
      :selected-transaction-provider="currentWithdrawalProvider"
      @update:selected-transaction-provider="updateCurrentProvider"
    />

    <list-transaction-providers
      v-if="transformWithdrawalProviders?.length && !isLoadingManualProviders"
      is-lite
      :transaction-providers="transformWithdrawalProviders"
      :selected-transaction-provider="currentManualWithdrawalProvider"
      @update:selected-transaction-provider="updateManualWithdrawalProvider"
    />

    <atomic-skeleton-card v-if="isLoadingManualProviders" :item-count="3" />

    <button-base type="primary" size="md" :isDisabled="buttonDisabled" :is-processing="isSending" @click="getWithdraw">
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.withdrawButton') }}
      {{ buttonAmount }}
      {{ formatAmountMin.currency }}
    </button-base>
  </form>
</template>

<script setup lang="ts">
  import useVuelidate from '@vuelidate/core';

  import type {
    IInvoice,
    IInvoicesRequestOptions,
    IPaginationMeta,
    IPaymentField,
    IPaymentGroup,
    IPaymentMethod,
  } from '@skeleton/core/types';
  import type { ITransactionProvider } from '@skeleton/types';
  import fieldsTypeMap from '@skeleton/maps/fieldsTypeMap.json';
  import { PayMethod } from '@skeleton/consts/method';
  import { Skeletor } from 'vue-skeletor';
  import { ModalName } from '@skeleton/consts/modals';

  interface SelectOption {
    value: string;
    minAmount: number;
    maxAmount: number;
    code: string;
  }

  const DESTINATION_TAG_VIEW_CODES = ['empty-network-Ton', 'TON'];

  const props = defineProps<{
    amountMax: number;
    amountMin: number;
    fields: IPaymentField[];
    method: string;
    methodId: string;
    changeMobileForm: () => void;
  }>();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const globalStore = useGlobalStore();
  const {
    popupsData,
    defaultLocalePopupsData,
    alertsData,
    defaultLocaleAlertsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    layoutData,
    defaultLocaleLayoutData,
  } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { showAlert } = useLayoutStore();
  const { closeModalSync } = useModalStore();
  const { formatBalance, getMainBalanceFormat, getContent } = useProjectMethods();
  const { currencyList, supportedTransactionAccounts, balanceObject, currentCurrencyId, isLoadingSupportedCurrencies } =
    useTransactionOperations(props);

  const { firstProvider, providers } = usePaymentProviders(props);

  const {
    getWithdrawalManualAgentProviders,
    transformWithdrawalProviders,
    isLoadingManualProviders,
    currentManualWithdrawalProvider,
    withdrawalManualProviders,
  } = useWithdrawalManualAgentMethods();

  const filteredCurrencyList = computed(() => {
    return currencyList.value?.filter(item => {
      const foundAccount = supportedTransactionAccounts.value.find(account => account.currency === item.name);
      return foundAccount?.withdrawalBalance || foundAccount?.id === currentCurrencyId.value;
    });
  });

  const updateManualWithdrawalProvider = provider => {
    currentManualWithdrawalProvider.value = provider;
  };

  const transactionStore = useTransactionStore();
  const { selectedCurrency, withdrawMethods } = storeToRefs(transactionStore);
  const { getWithdrawMethods } = transactionStore;

  const activeAccount = computed(() => {
    return supportedTransactionAccounts.value?.find(account => account.currency === selectedCurrency.value);
  });

  const searchCurrency = ref('');
  const balanceSelector = ref();
  const networkSelector = ref();

  const isOpenBalanceSelector = ref(false);
  const isOpenNetworkSelector = ref(false);
  const isSending = ref(false);

  const tryGetFieldName = (fieldKey: string, fieldName: string) => {
    const formatedMethodName = formatPayMethodKey(props.method);

    return (
      getContent(
        fieldsSettings.value,
        defaultLocaleFieldsSettings.value,
        `fieldsControls.${formatedMethodName}.${fieldKey}.${fieldName}`
      ) ||
      getContent(fieldsSettings.value, defaultLocaleFieldsSettings.value, `fieldsControls.${fieldKey}.${fieldName}`) ||
      ''
    );
  };

  const toggleBalanceSelector = (methodName: 'open' | 'close') => {
    balanceSelector.value[methodName]();
    searchCurrency.value = '';
  };

  const selectCurrency = async (accountId: string) => {
    isMobile.value ? toggleBalanceSelector('close') : (isOpenBalanceSelector.value = false);
    const currency = supportedTransactionAccounts.value.find(account => account.id === accountId)?.currency;
    await getWithdrawMethods(currency);
  };

  const invoice = ref<IInvoice>();
  const pageMeta = ref<IPaginationMeta>();
  const { getPlayerInvoices } = useCoreWalletApi();
  const loading = ref(true);

  const withdrawalProviders = ref<ITransactionProvider[]>([]);
  const currentWithdrawalProvider = ref<ITransactionProvider | null>();

  const filters = reactive<{ [key: string]: number | undefined | string }>({
    page: 1,
    perPage: 10,
    dateFrom: undefined,
    dateTo: undefined,
    type: 'all',
    currency: 'all',
    status: 'all',
  });

  const findPaymentMethodInGroups = (groups: IPaymentGroup[], method: string): IPaymentMethod | undefined => {
    return groups.flatMap(group => group.paymentMethods).find(paymentMethod => paymentMethod.method === method);
  };

  const networkSelectOptions = computed<SelectOption[]>(() => {
    const method = findPaymentMethodInGroups(withdrawMethods.value, props.method);

    const select = method?.fields.find((field: IPaymentField) => field.key === 'crypto_network');

    if (!select?.options) {
      return [];
    }

    const sortedOptions = sortNetworkList(select?.options || []);

    return sortedOptions.map(option => ({
      value: option.name,
      minAmount: option.minAmount,
      maxAmount: option.maxAmount,
      code: option.id || `empty-network-${option.name}`,
    }));
  });

  const state = reactive<{ selectedNetwork: string | undefined }>({
    selectedNetwork: networkSelectOptions.value?.[0]?.code,
  });

  const selectedNetworkData = computed(() => {
    return networkSelectOptions.value.find(option => option.code === state.selectedNetwork);
  });

  const formatAmountMax = computed(() => {
    return formatBalance(activeAccount.value?.currency, selectedNetworkData.value?.maxAmount ?? props.amountMax);
  });

  const formatAmountMin = computed(() => {
    return formatBalance(activeAccount.value?.currency, selectedNetworkData.value?.minAmount ?? props.amountMin);
  });

  const amountValue = ref(formatAmountMin.value.amount);

  const withdrawFormData = reactive<{ [key: string]: string }>({});

  props.fields.forEach((field: { [key: string]: string }) => {
    if (field.key !== 'crypto_network') {
      withdrawFormData[field.key] = field.value ?? undefined;
    }
  });

  const bonusBalance = computed(() => {
    return supportedTransactionAccounts.value?.find(item => item.id === currentCurrencyId.value);
  });

  const startRules = props.fields.reduce((currentRulesObj, currentField) => {
    if (currentField.key === 'crypto_network') return currentRulesObj;

    const rulesArr: { rule: string; arguments?: string }[] = [];
    if (currentField.isRequired) rulesArr.push({ rule: 'required' });

    if (currentField.key === 'phone') {
      rulesArr.push({ rule: 'phone' });
    } else if (currentField.regexp) {
      rulesArr.push({
        rule: 'regex',
        arguments: currentField.regexp,
      });
    } else if (currentField.key === 'wallet_id') {
      const findNetworkField = props.fields.find(field => field.key === 'crypto_network');
      const firstNetworkRegex = findNetworkField ? findNetworkField.options?.[0]?.regex : undefined;

      if (firstNetworkRegex) {
        rulesArr.push({
          rule: 'regex',
          arguments: firstNetworkRegex,
        });
      }
    }

    if (rulesArr.length) return { ...currentRulesObj, [currentField.key]: rulesArr };
    return currentRulesObj;
  }, {});

  const withdrawRules = ref(startRules);
  const { getFormRules } = useProjectMethods();
  const withdrawFormRules = computed(() => getFormRules(withdrawRules.value));
  const serverFormErrors = ref<{ [key: string]: Maybe<string> }>({});
  const v$ = useVuelidate(withdrawFormRules, withdrawFormData);

  const visibleFields = computed(() => {
    const {
      public: { showWalletFilledFields },
    } = useRuntimeConfig();

    props.fields.forEach(field => {
      if (field.value !== null && v$.value[field.key]?.$invalid) v$.value[field.key].$touch();
    });

    if (showWalletFilledFields) {
      return props.fields;
    }

    let filteredFields = props.fields.filter(
      field =>
        (field.value || field.value === null || v$.value[field.key]?.$invalid) &&
        field.key !== 'endpointId' &&
        field.key !== 'crypto_network'
    );

    if (state.selectedNetwork && !DESTINATION_TAG_VIEW_CODES.includes(state.selectedNetwork)) {
      filteredFields = filteredFields.filter(field => field.key !== 'destination_tag');
    }

    return filteredFields;
  });

  const onFocus = (fieldName: string): void => {
    if (serverFormErrors.value[fieldName]) {
      serverFormErrors.value[fieldName] = undefined;
    }
  };

  const setError = (fieldName: string): undefined | { variant: string; message: string } => {
    if (v$.value[fieldName]?.$error) {
      return {
        variant: 'error',
        message: v$.value[fieldName].$errors[0]?.$message,
      };
    }
    if (serverFormErrors.value[fieldName]) {
      return {
        variant: 'error',
        message: serverFormErrors.value[fieldName]?.[0] || '',
      };
    }
    return undefined;
  };

  const fieldsStore = useFieldsStore();

  const getFieldOptions = (fieldName: string) => {
    return fieldsStore.selectOptions[fieldName] || [];
  };

  const buttonAmount = computed(() => {
    if (amountValue.value > formatAmountMax.value.amount) return formatAmountMax.value.amount;
    if (amountValue.value < formatAmountMin.value.amount) return formatAmountMin.value.amount;
    return amountValue.value;
  });

  const activeAccountWithdrawalFormat = computed(() =>
    formatBalance(activeAccount.value?.currency, activeAccount.value?.withdrawalBalance)
  );

  const fieldHint = computed(() => {
    const minContent = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.withdraw.minSum') || '';
    const maxContent = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.withdraw.maxSum') || '';
    const minAmountContent = `${minContent} ${formatAmountMin.value.amount} ${formatAmountMin.value.currency}`;
    const maxAmountContent = `${maxContent} ${formatAmountMax.value.amount} ${formatAmountMax.value.currency}`;

    return {
      message: `${minAmountContent}, ${maxAmountContent}`,
    };
  });

  const buttonDisabled = computed(
    () =>
      v$.value.$invalid ||
      amountValue.value > activeAccountWithdrawalFormat.value.amount ||
      amountValue.value < formatAmountMin.value.amount ||
      amountValue.value > formatAmountMax.value.amount ||
      (props.method === PayMethod.MANUAL_AGENT_WITHDRAW && !currentManualWithdrawalProvider.value) ||
      isSending.value
  );

  const setNetwork = async (code: string) => {
    let networkRegex;
    state.selectedNetwork = code;
    isMobile.value ? toggleNetworkSelector('close') : (isOpenNetworkSelector.value = false);
    const findNetworkField = props.fields.find(field => field.key === 'crypto_network');
    if (findNetworkField) {
      const findNetworkOption = findNetworkField?.options?.find(option => {
        if (option.id) return option.id === state.selectedNetwork;
        return state.selectedNetwork === `empty-network-${option.name}`;
      });

      networkRegex = findNetworkOption?.regex;
    }

    withdrawRules.value = {
      ...withdrawRules.value,
      wallet_id: networkRegex
        ? [
            { rule: 'required' },
            {
              rule: 'regex',
              arguments: networkRegex,
            },
          ]
        : [{ rule: 'required' }],
    };

    amountValue.value = formatAmountMin.value.amount;
  };

  const resolveInvoicesRequest = async (): Promise<void> => {
    loading.value = true;
    const requestOptions: IInvoicesRequestOptions = {} as IInvoicesRequestOptions;
    Object.keys(filters).forEach(param => {
      if (filters[param] && filters[param] !== 'all') requestOptions[param] = filters[param];
    });
    const response = await getPlayerInvoices(requestOptions);
    invoice.value = response.data.length ? response.data[0] : undefined;
    pageMeta.value = response.meta;
    loading.value = false;
  };

  const getWithdraw = async (): Promise<void> => {
    if (buttonDisabled.value) return;

    const requestFormData = {
      ...withdrawFormData,
      phone: withdrawFormData.phone ? `+${withdrawFormData.phone}` : undefined,
    };

    const networkValue = state.selectedNetwork?.includes('empty-network') ? null : state.selectedNetwork;
    const fields: any = {
      ...requestFormData,
    };

    if (props.method === PayMethod.WITHDRAW_WALLET_DIRECT_METHOD) {
      fields.crypto_network = networkValue;
    }

    if (props.method === PayMethod.BILLBLEND_WITHDRAWAL_CARD) {
      if (currentWithdrawalProvider.value?.endpoint) {
        fields.endpointId = currentWithdrawalProvider.value.endpoint;
      }

      const { tgUserInfo } = useProfileStore();

      if (tgUserInfo?.username) {
        fields.playerUsername = tgUserInfo?.username;
      } else if (tgUserInfo?.firstName || tgUserInfo?.lastName) {
        `${tgUserInfo?.firstName || ' '} ${tgUserInfo?.lastName || ' '}`.trim();
      } else {
        fields.playerUsername = `${fields?.methodFirstName || ' '} ${fields?.methodLastName || ' '}`.trim();
      }
    }

    const mainCurrencyAmount = getMainBalanceFormat(
      activeAccountWithdrawalFormat.value.currency,
      Number(amountValue.value)
    );

    if (props.method === PayMethod.MANUAL_AGENT_WITHDRAW) {
      const provider = withdrawalManualProviders.value.find(
        item => item.name === currentManualWithdrawalProvider.value?.name
      );
      fields.paymentMethodId = provider?.id?.toString();
      fields.amount = mainCurrencyAmount.amount;
    }

    const params = {
      method: props.method,
      methodId: props.methodId,
      currency: activeAccount.value?.currency || '',
      amount: mainCurrencyAmount.amount,
      accountId: activeAccount.value?.id || '',
      fields,
    };

    const { withdrawAccount } = useCoreWalletApi();

    try {
      isSending.value = true;
      await withdrawAccount(params);
      showAlert(
        alertsData.value?.wallet?.withdrawalProcessed || defaultLocaleAlertsData.value?.wallet?.withdrawalProcessed
      );

      if (
        !(
          props.method === PayMethod.BILLBLEND_WITHDRAWAL_CARD ||
          props.method === PayMethod.WITHDRAW_WALLET_DIRECT_METHOD ||
          props.method === PayMethod.MANUAL_AGENT_WITHDRAW
        )
      ) {
        await resolveInvoicesRequest();
      }

      closeModalSync(ModalName.WALLET);
      await navigateTo('/');
    } catch (err: any) {
      if (err.response?.status === 422) {
        serverFormErrors.value = err.data?.error?.fields;
      } else {
        showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
      }
    } finally {
      isSending.value = false;
    }
  };

  const updateCurrentProvider = (provider: ITransactionProvider) => {
    currentWithdrawalProvider.value = provider;
  };

  const toggleNetworkSelector = (methodName: 'open' | 'close') => {
    networkSelector.value[methodName]();
  };

  onBeforeMount(async () => {
    withdrawalProviders.value = providers.value.map(provider => {
      return {
        ...provider,
        img: `${gamehubCdn}/payments/${provider.id}.svg`,
      };
    });
    currentWithdrawalProvider.value = firstProvider.value;
    if (props.method === PayMethod.MANUAL_AGENT_WITHDRAW) {
      await getWithdrawalManualAgentProviders();
    }
  });
</script>

<style src="~/assets/styles/components/form/withdraw.scss" lang="scss" />
