<template>
  <div class="transaction-providers">
    <ul class="transaction-providers__list">
      <li v-for="item in transactionProviders" :key="item.id" class="transaction-providers__provider">
        <button
          type="button"
          :class="[
            'transaction-providers__provider-item',
            {
              'transaction-providers__provider-item_active': selectedTransactionProvider?.id === item.id,
              'transaction-providers__provider-item--lite': isLite,
            },
          ]"
          @click.prevent="selectProvider(item)"
        >
          <span class="transaction-providers__provider-img">
            <atomic-image :src="item.img" />
          </span>
          <span :class="formDepositFeeClass">{{ item.name }}</span>
        </button>
      </li>
    </ul>
    <div v-if="error" class="transaction-providers__error">{{ error }}</div>
  </div>
</template>

<script setup lang="ts">
  import type { ITransactionProvider } from '~/types';

  type IProvider = ITransactionProvider & { img: string };

  interface IProps {
    selectedTransactionProvider?: ITransactionProvider;
    transactionProviders: IProvider[];
    isLite?: boolean;
    error?: string;
  }

  const props = defineProps<IProps>();

  const formDepositFeeClass = computed(() => ({
    'transaction-providers__provider-fee': true,
    'transaction-providers__provider-fee--lite': props.isLite,
  }));
  const emit = defineEmits(['update:selectedTransactionProvider']);

  const selectProvider = (provider: ITransactionProvider) => {
    emit('update:selectedTransactionProvider', provider);
  };
</script>

<style src="~/assets/styles/components/list/transaction-providers.scss" lang="scss" />
