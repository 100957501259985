<template>
  <div class="form-deposit-manual-cash-agent">
    <div class="form-deposit-manual-cash-agent__introduction">
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.manualAgent.introduction') }}
    </div>

    <div v-if="isLoadingProviders || isLoading" class="form-deposit-manual-cash-agent__skeletor">
      <skeletor v-for="i in 3" :key="i" class="form-deposit-manual-cash-agent__skeletor-item" />
    </div>

    <template v-else>
      <list-transaction-providers
        v-if="depositManualProviders?.length"
        :transaction-providers="transformProviders"
        :selected-transaction-provider="currentManualDepositProvider"
        :error="selectProviderError"
        @update:selected-transaction-provider="updateDepositProvider"
      />

      <atomic-empty-method v-else :text="getContent(popupsData, defaultLocalePopupsData, 'wallet.notAvailableText')" />
    </template>

    <button-copy-id
      class="form-deposit-manual-cash-agent__copy"
      :btnText="getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cash_agent.copyIdBtn')"
      :code="depositManualProviderInfo?.accountNumber"
      label="Agent ID"
      name="uniqueNumber"
      @copy-id="copyId"
    />

    <form-input-number
      v-model:value="amountValue"
      :hint="fieldHint"
      :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.manualAgentDeposit.amount.label')"
      :placeholder="
        getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.manualAgentDeposit.amount.placeholder')
      "
      name="amount"
      :min="formatAmountMin.amount"
      :max="formatAmountMax.amount"
      :currency="selectedCurrency"
      :default-value="formatAmountMin.amount"
    />

    <form-input-text
      v-model:value="accountNumber"
      :label="
        getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.manualAgentDeposit.accountNumber.label')
      "
      :placeholder="
        getContent(
          fieldsSettings,
          defaultLocaleFieldsSettings,
          'fieldsControls.manualAgentDeposit.accountNumber.placeholder'
        )
      "
      name="accountNumber"
      :hint="setError('accountNumber')"
    />

    <form-input-text
      v-model:value="transactionId"
      :label="
        getContent(
          fieldsSettings,
          defaultLocaleFieldsSettings,
          'fieldsControls.manualAgentDeposit.transactionNumber.label'
        )
      "
      :placeholder="
        getContent(
          fieldsSettings,
          defaultLocaleFieldsSettings,
          'fieldsControls.manualAgentDeposit.transactionNumber.placeholder'
        )
      "
      name="transactionNumber"
      :hint="transactionIdError()"
    />

    <div class="form-deposit-manual-cash-agent__instruction">
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.manualAgent.instruction') }}
    </div>

    <button-base
      type="primary"
      :isDisabled="isDisabledDepositBtn"
      :is-processing="isDisabledDepositBtn"
      @click="onClickConfirm"
    >
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.buttonConfirm') }}
    </button-base>
  </div>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { Skeletor } from 'vue-skeletor';

  import type { IPaymentField, IPaymentMethod } from '@skeleton/core/types';
  import type { ITransactionProvider } from '@skeleton/types';
  import { ModalName } from '@skeleton/consts/modals';
  import { PayMethod } from '@skeleton/consts/method';

  const props = defineProps<{
    amountMax?: number;
    amountMin?: number;
    method?: string;
    fields: IPaymentField[];
    changeMobileForm?: () => void;
    selectedProvider?: IPaymentMethod | null;
  }>();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { getContent, formatBalance, createValidationRules, getFormRules } = useProjectMethods();
  const {
    popupsData,
    defaultLocalePopupsData,
    alertsData,
    defaultLocaleAlertsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
  } = storeToRefs(useGlobalStore());

  const { showAlert } = useLayoutStore();
  const { closeModalSync } = useModalStore();

  const {
    getManualDepositProviders,
    getManualDepositProviderInfo,
    depositManualProviders,
    depositManualProviderInfo,
    isLoadingProviders,
    currentManualDepositProvider,
  } = useDepositManualAgentMethods();

  const isLoading = ref(true);

  const { currentCurrencyId, selectedCurrency } = useTransactionOperations(props);

  const isUniqueNumberAlertShown = ref(false);
  const isDisabledDepositBtn = ref(false);
  const existingTransactionError = ref(false);
  const amountValue = ref<string>();
  const accountNumber = ref<string>();
  const transactionId = ref<string>();
  const selectProviderError = ref<string>();

  const formatAmountMax = computed(() => formatBalance(selectedCurrency.value, props.amountMax));
  const formatAmountMin = computed(() => formatBalance(selectedCurrency.value, props.amountMin));

  const depositFormData = computed(() => {
    return {
      amount: amountValue.value,
      accountNumber: accountNumber.value,
      transactionNumber: transactionId.value,
    };
  });

  const depositRules = createValidationRules(
    [
      { name: 'amount', isRequired: true },
      { name: 'accountNumber', isRequired: true },
      { name: 'transactionNumber', isRequired: true },
    ],
    true
  );

  const depositFormRules = getFormRules(depositRules);

  const { v$, setError } = useFormValidation(depositFormRules, depositFormData);

  const fieldHint = computed(() => ({
    variant: '',
    message: `${
      getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minSum') || ''
    } ${formatAmountMin.value.amount} ${formatAmountMin.value.currency}`,
  }));

  const transformProviders = computed(() => {
    return depositManualProviders.value.map(item => {
      const id = item?.name?.toLowerCase();
      return {
        ...item,
        id,
        img: `${gamehubCdn}/payments/${id}.svg`,
      };
    });
  });

  const onClickConfirm = async () => {
    v$.value.$reset();
    selectProviderError.value = '';

    const validFormData = await v$.value.$validate();

    if (!validFormData || !depositManualProviderInfo.value) {
      if (!depositManualProviderInfo.value) {
        selectProviderError.value = getContent(
          popupsData.value,
          defaultLocalePopupsData.value,
          'wallet.deposit.manualAgent.error.selectProvider'
        );
      }
      return;
    }

    const { depositAccount } = useCoreWalletApi();

    try {
      isDisabledDepositBtn.value = true;
      existingTransactionError.value = false;
      const amount = Number.parseInt(amountValue.value ?? '0');

      const params = {
        method: PayMethod.MANUAL_AGENT_DEPOSIT,
        currency: selectedCurrency.value,
        amount,
        accountId: currentCurrencyId.value,
        redirectSuccessUrl: '',
        redirectErrorUrl: '',
        fields: {
          transactionNumber: transactionId.value,
          accountNumber: accountNumber.value,
          amount,
          providerId: depositManualProviderInfo.value?.id,
        },
      };

      await depositAccount(params);
      closeModalSync(ModalName.WALLET);
      await navigateTo('/');
    } catch (e: any) {
      if (e.data?.error?.fields?.message === 'Invalid transaction ID') {
        existingTransactionError.value = true;
      }
      console.log(e);
    } finally {
      isDisabledDepositBtn.value = false;
    }
  };

  const copyId = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      if (value && !isUniqueNumberAlertShown.value) {
        showAlert(
          alertsData.value?.wallet?.copyUniqueNumberSuccess ||
            defaultLocaleAlertsData.value?.wallet?.copyUniqueNumberSuccess
        );
      }
    } catch (e) {
      console.log('Error copying value:', e);
    }
  };

  const updateDepositProvider = (provider: ITransactionProvider & { img: string }) => {
    const { img, ...providerWithoutImg } = provider;
    currentManualDepositProvider.value = providerWithoutImg;
  };

  const transactionIdError = () => {
    return existingTransactionError.value
      ? {
          variant: 'error',
          message: getContent(
            popupsData.value,
            defaultLocalePopupsData.value,
            'wallet.deposit.manualAgent.error.invalidTransactionId'
          ),
        }
      : setError('transactionNumber');
  };

  watch(selectedCurrency, async () => {
    if (selectedCurrency.value) {
      await getManualDepositProviders(selectedCurrency.value);
      isLoading.value = false;
    }
  });

  watch(currentManualDepositProvider, () => {
    depositManualProviderInfo.value = undefined;
    getManualDepositProviderInfo();
  });

  watch(
    () => formatAmountMin.value,
    newValue => {
      if (newValue && newValue.amount !== undefined) {
        amountValue.value = newValue.amount;
      }
    },
    { immediate: true }
  );

  watch(transactionId, newVal => {
    if (newVal !== newVal.toUpperCase()) {
      transactionId.value = newVal.toUpperCase();
    }
  });
</script>

<style lang="scss" src="~/assets/styles/components/form/deposit-manual-cash-agent.scss" />
