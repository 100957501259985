import * as Sentry from '@sentry/nuxt';
import { storeToRefs } from 'pinia';

import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

export const useDepositManualAgentMethods = () => {
  type ManualProvider = {
    id: number;
    currency: string;
    name: string;
  }

  const { selectedCurrency } = storeToRefs(useTransactionStore());

  const depositManualProviders = ref<ManualProvider[]>([]);
  const isLoadingProviders = ref(false);
  const isLoadingProviderInfo = ref(false);
  const depositManualProviderInfo = ref();
  const currentManualDepositProvider = ref();

  const getManualDepositProviders = async (currency?: string) => {
    try {
      isLoadingProviders.value = true;
      const res = await useApiAuthInstance(`/api/payment/manual-agents/deposit-providers?currency=${currency || selectedCurrency.value}`);
      depositManualProviders.value = res?.data;
    } catch (e) {
      Sentry.captureException(e);
      depositManualProviders.value = [];
      console.log(e);
    } finally {
      isLoadingProviders.value = false;
    }
  };

  const getManualDepositProviderInfo = async () => {
    try {
      isLoadingProviderInfo.value = true;
      const provider = depositManualProviders.value?.find((item) => item.name === currentManualDepositProvider.value.name);
      const res = await useApiAuthInstance(`/api/payment/manual-agents/get-provider-info?provider_id=${provider?.id}`);
      depositManualProviderInfo.value = res?.data;
    } catch (e) {
      Sentry.captureException(e);
      console.log('Error getProviderInfo', e);
    } finally {
      isLoadingProviderInfo.value = false;
    }
  };

  return {
    getManualDepositProviders,
    getManualDepositProviderInfo,
    depositManualProviders,
    depositManualProviderInfo,
    isLoadingProviders,
    isLoadingProviderInfo,
    currentManualDepositProvider
  };
};
