import { defineStore } from 'pinia';
import type { IBonus } from '@skeleton/core/types';

interface IWalletState {
  loadingCryptoMethods: boolean;
  cryptoDepositMethods: any[];
  cryptoDepositBonuses: IBonus[];
  cryptoDepositLimitError: boolean;
  depositCryptoCurrency: string | undefined;
}

export const useProjectWallet = defineStore('projectWallet', {
  state: (): IWalletState => ({
    loadingCryptoMethods: true,
    cryptoDepositMethods: [],
    cryptoDepositBonuses: [],
    cryptoDepositLimitError: false,
    depositCryptoCurrency: undefined,
  }),

  actions: {
    async setDepositCryptoCurrency(currencyCode?: string): Promise<void> {
      const { activeAccountType, activeAccount, accounts, createAccount } = useWalletStore();

      if (currencyCode) {
        const hasAccount = accounts.find((account) => account.currency === currencyCode);
        if (!hasAccount) {
          await createAccount(currencyCode);
        }
        this.depositCryptoCurrency = currencyCode;
      } else if (activeAccountType === 'fiat') {
        const { currencies } = useGlobalStore();
        const tetherAccount = accounts.find((account) => account.currency === 'USDT');
        const tetherCurrency = currencies.find((currency) => currency.code === 'USDT');

        if (!tetherAccount && tetherCurrency) {
          await createAccount('USDT');
        }

        if (tetherCurrency) {
          this.depositCryptoCurrency = 'USDT';
        } else {
          const firstCrypto = currencies.find((currency) => currency.type === 'crypto');
          if (firstCrypto) {
            const firstCryptoAccount = accounts.find((account) => account.currency === firstCrypto.code);
            if (!firstCryptoAccount) {
              await createAccount(firstCrypto.code);
            }
            this.depositCryptoCurrency = firstCrypto.code;
          } else this.depositCryptoCurrency = undefined;
        }
      } else {
        this.depositCryptoCurrency = activeAccount?.currency;
      }
    },

    async getDepositCryptoMethods(): Promise<void> {
      const { getDepositMethods } = useCoreWalletApi();

      try {
        this.cryptoDepositMethods = await getDepositMethods(this.depositCryptoCurrency || '');
      } catch (err: any) {
        this.cryptoDepositMethods = [];
        if (err.data?.error?.code === 13100) {
          this.cryptoDepositLimitError = true;
          const { getLimits } = useLimitsStore();
          await getLimits();
        }
      }
    },

    async getDepositMethods(currencyCode?: string): Promise<void> {
      this.cryptoDepositMethods = [];
      this.cryptoDepositLimitError = false;
      await this.setDepositCryptoCurrency(currencyCode);

      if (currencyCode) {
        await this.getDepositCryptoMethods();
      } else {
        const { depositMethods, depositLimitError } = useTransactionStore();
        this.cryptoDepositMethods = depositMethods;
        this.cryptoDepositLimitError = depositLimitError;
      }
    },

    async getDepositBonuses(currencyCode?: string): Promise<void> {
      const { getDepositBonuses } = useCoreBonusApi();

      if (currencyCode) {
        try {
          this.cryptoDepositBonuses = await getDepositBonuses(this.depositCryptoCurrency || '');
        } catch {
          this.cryptoDepositBonuses = [];
        }
      } else {
        const { depositBonuses } = useBonusStore();
        this.cryptoDepositBonuses = depositBonuses;
      }
    },

    async getWalletData(currencyCode?: string): Promise<void> {
      this.loadingCryptoMethods = true;
      await Promise.allSettled([this.getDepositMethods(currencyCode), this.getDepositBonuses(currencyCode)]);
      this.loadingCryptoMethods = false;
    },
  },
});
