<template>
  <div class="wallet-crypto-address">
    <div v-if="label" class="wallet-crypto-address__label">{{ label }}</div>
    <div class="wallet-crypto-address__field">
      <div class="wallet-crypto-address__address" @click="emit('input-click')">
        <span>{{ viewAddress[0] }}</span>
        <span>{{ viewAddress[1] }}</span>
        <span>{{ viewAddress[2] }}</span>
      </div>
      <button class="wallet-crypto-address__btn" :disabled="!address" @click.prevent="emit('qr-click')">
        <atomic-icon :id="iconId" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  interface IProps {
    label?: string;
    address: string;
    iconId: string;
    maxAddressLength?: number;
  }

  const LIGHT_SYMBOL_COUNT = 4;

  const emit = defineEmits(['qr-click', 'input-click']);

  const { address, maxAddressLength = 27 } = defineProps<IProps>();

  const viewAddress = computed<[string, string, string]>(() => {
    const ell = '...';
    const minLength = LIGHT_SYMBOL_COUNT * 2;

    if (address.length <= minLength) {
      return ['', address, ''];
    }

    const start = address.slice(0, LIGHT_SYMBOL_COUNT);
    let center = address.slice(LIGHT_SYMBOL_COUNT, -LIGHT_SYMBOL_COUNT);
    const end = address.slice(-LIGHT_SYMBOL_COUNT);

    if (address.length <= maxAddressLength) {
      return [start, center, end];
    }

    const x = (maxAddressLength - 2 * LIGHT_SYMBOL_COUNT - ell.length) / 2;
    const keepLeft = Math.ceil(x);
    const keepRight = Math.floor(x);

    center = center.slice(0, keepLeft) + ell + center.slice(center.length - keepRight);

    return [start, center, end];
  });
</script>

<style src="~/assets/styles/components/wallet/crypto/address.scss" lang="scss" />
