<template>
  <form class="cash-agent-deposit">
    <span class="title">{{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cash_agent.title') }}</span>
    <wallet-limit show-all-limits />
    <button-copy-id
      :btnText="getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cash_agent.copyIdBtn')"
      :code="profile?.uniqNumber"
      :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cash_agent.copyIdLabel')"
      name="uniqueNumber"
      @copy-id="copyId"
    />
    <span class="warning">{{
      getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.cash_agent.description')
    }}</span>
  </form>
</template>

<script lang="ts" setup>
  const isUniqueNumberAlertShown = ref(false);

  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);

  const { getContent } = useProjectMethods();

  const globalStore = useGlobalStore();

  const { popupsData, defaultLocalePopupsData, alertsData, defaultLocaleAlertsData } = storeToRefs(globalStore);

  const { showAlert } = useLayoutStore();

  const copyId = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      if (value && !isUniqueNumberAlertShown.value) {
        showAlert(
          alertsData.value?.wallet?.copyUniqueNumberSuccess ||
            defaultLocaleAlertsData.value?.wallet?.copyUniqueNumberSuccess
        );
      }
    } catch (e) {
      console.log('Error copying value:', e);
    }
  };
</script>

<style src="~/assets/styles/components/form/deposit-cash-agent.scss" lang="scss" />
