<template>
  <form-deposit-confirmation
    v-if="showConfirmation"
    :redirect-url="redirectProviderUrl"
    :labels-data="fiatDepositInfo"
    :method="props.method"
    @on-click-back-btn="showConfirmation = false"
    @on-click-deposit-btn="confirmDeposit"
  />
  <form v-else class="form-deposit-bank">
    <!-- Crypto balance-->

    <skeletor v-if="isLoadingSupportedCurrencies" class="form-deposit-bank__skeletor" />

    <template v-else>
      <form-input-display
        v-click-outside="() => (isOpenBalanceSelector ? (isOpenBalanceSelector = false) : null)"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        :is-open="isOpenBalanceSelector"
        :is-mobile="isMobile"
        @click-selector="
          isMobile ? toggleBottomSheetBalanceSelector('open') : (isOpenBalanceSelector = !isOpenBalanceSelector)
        "
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrencyField"
              :placeholder="
                getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')
              "
            />
          </div>
        </template>

        <list-simple
          v-if="!isMobile"
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrencyField"
          :list="currencyList"
          @selected="selectCurrency"
        />
      </form-input-display>

      <client-only v-if="isMobile">
        <layout-bottom-sheet ref="cryptoSelector" @closed="isOpenBalanceSelector = false">
          <template #header>
            <span class="title">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.selectCurrency') }}
            </span>
            <div class="header">
              <form-input-search
                v-model:value="searchCurrencyField"
                :placeholder="
                  getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')
                "
              />
            </div>
          </template>
          <list-simple
            v-model:value="currentCurrencyId"
            :searchValue="searchCurrencyField"
            :list="currencyList"
            @selected="selectCurrency"
          />
          <template #footer>
            <button-base type="gray" size="sm" @click="toggleBottomSheetBalanceSelector('close')">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
            </button-base>
          </template>
        </layout-bottom-sheet>
      </client-only>
    </template>

    <wallet-limit
      v-model:limit-error="limitError"
      v-model:deposit-error="depositError"
      :selected-currency="selectedCurrency"
      :deposit-balance="providerOffer?.amountExpected || 0"
    />

    <template v-if="!depositError">
      <!-- Fiat -->

      <div
        :class="[
          'tap-view-selector',
          { 'tap-view-selector_open': isOpenFiatSelector, 'tap-view-selector_has-error': limitError },
        ]"
      >
        <div class="tap-view-selector__label">
          {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.depositAmount.label`) }}
        </div>
        <div
          v-click-outside="() => (isOpenFiatSelector ? (isOpenFiatSelector = false) : null)"
          class="tap-view-selector__inner"
        >
          <div class="tap-view-selector__inner-left">
            <form-input-number
              v-model:value="amountValue"
              label=""
              name="depositSum"
              :min="props.amountMin || 0"
              :max="props.amountMax || 0"
              :defaultValue="defaultValue"
              class="form-deposit-bank__amount"
            />
          </div>
          <div class="tap-view-selector__inner-right">
            <button
              class="tap-view-selector__button"
              type="button"
              @click.prevent="isMobile ? toggleFiatSelector('open') : (isOpenFiatSelector = !isOpenFiatSelector)"
            >
              <span class="tap-view-selector__icon">
                <atomic-icon id="arrow-down" />
              </span>
              {{ currentFiatCurrency }}
            </button>
          </div>

          <div v-if="!isMobile" class="tap-view-selector__list">
            <div class="header">
              <form-input-search
                v-model:value="searchFiatCurrencyField"
                :placeholder="
                  getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')
                "
              />
            </div>
            <div class="tap-view-selector__list-inner">
              <list-simple
                v-model:value="currentFiatCurrency"
                :searchValue="searchFiatCurrencyField"
                :list="fiatCurrencies"
                @selected="isMobile ? toggleFiatSelector('close') : (isOpenFiatSelector = false)"
              />
            </div>
          </div>
        </div>

        <div class="tap-view-selector__notification">
          <div class="tap-view-selector__notification-left">
            <atomic-hint v-if="fieldHint" v-bind="fieldHint" />
          </div>
          <div v-if="amountExpected" class="tap-view-selector__notification-right">
            ≈{{ amountExpected }} {{ selectedCurrency }}
          </div>
        </div>
      </div>

      <client-only v-if="isMobile" @closed="isOpenFiatSelector = false">
        <layout-bottom-sheet ref="fiatSelector">
          <template #header>
            <span class="title">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.selectCurrency') }}
            </span>
            <div class="header">
              <form-input-search
                v-model:value="searchFiatCurrencyField"
                :placeholder="
                  getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.search.placeholder')
                "
              />
            </div>
          </template>
          <list-simple
            v-model:value="currentFiatCurrency"
            :searchValue="searchFiatCurrencyField"
            :list="fiatCurrencies"
            @selected="toggleFiatSelector('close')"
          />
          <template #footer>
            <button-base type="gray" size="sm" @click="toggleFiatSelector('close')">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
            </button-base>
          </template>
        </layout-bottom-sheet>
      </client-only>

      <!-- Country -->

      <form-input-display
        v-click-outside="() => (isOpenCountrySelector ? (isOpenCountrySelector = false) : null)"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.country.label`)"
        :value="countryName"
        :img-url="`${flagUrl}/${currentCountry.toLowerCase()}.svg`"
        :sheetTitle="getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins')"
        :is-open="isOpenCountrySelector"
        :is-mobile="isMobile"
        @click-selector="
          isMobile ? toggleBottomSheetCountrySelector('open') : (isOpenCountrySelector = !isOpenCountrySelector)
        "
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search v-model:value="searchCountryField" placeholder="Search" />
          </div>
        </template>
        <div v-if="!isMobile" class="form-deposit-bank__fiat-list">
          <div class="tap-view-selector__text">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.countryAutomatic') }}
          </div>
          <list-simple
            v-model:value="currentCountry"
            :searchValue="searchCountryField"
            :list="countries"
            @selected="isMobile ? toggleBottomSheetCountrySelector('close') : (isOpenCountrySelector = false)"
          />
        </div>
      </form-input-display>

      <client-only v-if="isMobile" @closed="isOpenCountrySelector = false">
        <layout-bottom-sheet ref="countrySelector">
          <template #header>
            <div class="title tap-view-selector__title-center">
              {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.country.label`) }}
            </div>
            <div class="header">
              <form-input-search v-model:value="searchCountryField" placeholder="Search" />
            </div>
          </template>
          <div class="tap-view-selector__text">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.countryAutomatic') }}
          </div>
          <list-simple
            v-model:value="currentCountry"
            :searchValue="searchCountryField"
            :list="countries"
            @selected="toggleBottomSheetCountrySelector('close')"
          />
          <template #footer>
            <button-base type="gray" size="sm" @click="toggleBottomSheetCountrySelector('close')">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.closeBtn') }}
            </button-base>
          </template>
        </layout-bottom-sheet>
      </client-only>

      <template v-if="props.method">
        <template v-if="!isProvidersLoading">
          <template v-if="currentProviders.length">
            <ul class="form-deposit-bank__methods">
              <li v-for="item in methodKeys" :key="item" class="form-deposit-bank__method">
                <button
                  :class="[
                    'form-deposit-bank__method-item',
                    { 'form-deposit-bank__method-item_active': currentMethod === item },
                  ]"
                  type="button"
                  @click.prevent="setMethod(item)"
                >
                  <atomic-image
                    class="form-deposit-bank__method-img"
                    :src="`${gamehubCdn}/payments/${getMethodIconName(item)}.svg`"
                  />
                  <span v-if="currentMethod === item" class="form-deposit-bank__method-decorator" />
                </button>
              </li>
            </ul>

            <ul class="form-deposit-bank__providers">
              <li v-for="item in currentProviders" :key="item.providerCode" class="form-deposit-bank__provider">
                <button
                  type="button"
                  :class="[
                    'form-deposit-bank__provider-item',
                    {
                      'form-deposit-bank__provider-item_active': currentProvider?.providerCode === item.providerCode,
                    },
                  ]"
                  @click.prevent="setProvider(item)"
                >
                  <span class="form-deposit-bank__provider-img">
                    <atomic-image :src="`${gamehubCdn}/payments/${item.providerCode}.svg`" />
                  </span>
                  <span class="form-deposit-bank__provider-fee"> Fee {{ getFee(item) }} </span>
                </button>
              </li>
            </ul>
          </template>

          <div v-else class="empty-payment">
            <div class="empty-payment__title">
              {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'profile.wallet.noPaymentMethods') }}
            </div>
            <div class="empty-payment__text">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.tryAnotherRegion') }}
            </div>
          </div>
        </template>

        <div v-else class="form-deposit-bank__skeletor">
          <skeletor v-for="i in 3" :key="i" class="form-deposit-bank__skeletor-item" />
        </div>

        <bonus-deposit-code class="form-deposit-crypto__bonus" />

        <div v-if="providerOffer" class="form-deposit-bank__offer">
          <div class="form-deposit-bank__offer-row">
            <p class="form-deposit-bank__offer-key">
              {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.depositAmount.label`) }}
            </p>
            <p class="form-deposit-bank__offer-value">{{ providerOffer.depositAmount }} {{ currentFiatCurrency }}</p>
          </div>
          <div class="form-deposit-bank__offer-row">
            <p class="form-deposit-bank__offer-key">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.fee') }}
            </p>
            <p class="form-deposit-bank__offer-value">-{{ providerOffer.fee }} {{ currentFiatCurrency }}</p>
          </div>
          <div class="form-deposit-bank__offer-row">
            <p class="form-deposit-bank__offer-key">
              {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.youWillGet') }}
            </p>
            <p class="form-deposit-bank__offer-value">{{ providerOffer.amountExpected }} {{ selectedCurrency }}</p>
          </div>
        </div>

        <button-base
          type="primary"
          size="md"
          :is-disabled="isDisabledDepositBtn"
          :is-processing="isLoading"
          @click.prevent="onClickDeposit"
        >
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.deposit.depositButton') }}
          {{ amountValue }} {{ currentFiatCurrency }}
        </button-base>
      </template>

      <atomic-empty-method v-else :text="getContent(popupsData, defaultLocalePopupsData, 'wallet.notAvailableText')" />
    </template>
  </form>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import debounce from 'lodash/debounce';
  import * as Sentry from '@sentry/nuxt';
  import { Skeletor } from 'vue-skeletor';
  import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

  import type { IPaymentField } from '@skeleton/core/types';
  import type { IProfileLimits } from '~/types';
  import { ModalName } from '@skeleton/consts/modals';

  interface IProvider {
    providerCode: string;
    fee: string;
    amountExpectedTo: string;
    amountFrom: string;
    paymentMethodOffer: Array<{
      amountExpectedTo: string;
      methodName: string;
      method: string;
      fee: string;
    }>;
  }

  const props = defineProps<{
    amountMax?: number;
    amountMin?: number;
    method: string;
    fields?: IPaymentField[];
    currentLocaleLimitsContent?: Maybe<IProfileLimits['coolingOff']>;
    defaultLocaleLimitsContent?: Maybe<IProfileLimits['coolingOff']>;
  }>();

  const emit = defineEmits(['clickBackBtn']);

  const MethodKey = {
    VISA: 'Visa / Mastercard',
    APPLE: 'Apple Pay / Google Pay',
    SEPA: 'SEPA',
    IDEAL: 'iDEAL',
  };

  const { getContent } = useProjectMethods();
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { showAlert } = useLayoutStore();
  const { closeModalSync } = useModalStore();
  const { getDepositMethods } = useTransactionStore();

  const {
    popupsData,
    defaultLocalePopupsData,
    layoutData,
    defaultLocaleLayoutData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    alertsData,
    defaultLocaleAlertsData,
  } = storeToRefs(useGlobalStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const profileStore = useProfileStore();

  const {
    currencyList,
    supportedTransactionAccounts,
    balanceObject,
    currentCurrencyId,
    selectedCurrency,
    isLoadingSupportedCurrencies,
  } = useTransactionOperations(props);
  const { limitsContent, defaultLimitsContent } = storeToRefs(useLimitsStore());

  const { iconUrl: flagUrl } = useDynamicIcon('flags');

  const isOpenBalanceSelector = ref(false);
  const isOpenFiatSelector = ref(false);
  const isOpenCountrySelector = ref(false);
  const showConfirmation = ref(false);
  const redirectProviderUrl = ref('');
  const limitError = ref(false);
  const depositError = ref(false);

  const allProviderList = ref<IProvider[]>([]);
  const currentProvider = ref<IProvider | null>(null);

  const cryptoSelector = ref();
  const fiatSelector = ref();
  const countrySelector = ref();

  const amountValue = ref(0);
  const defaultValue = ref(0);
  const currentFiatCurrency = ref('');
  const currentCountry = ref('');
  const methodKeys = ref<string[]>(Object.values(MethodKey));
  const currentMethod = ref(MethodKey.VISA);
  const isLoading = ref(false);
  const isProvidersLoading = ref(false);

  const searchCurrencyField = ref('');
  const searchFiatCurrencyField = ref('');
  const searchCountryField = ref('');

  const localCurrencyField = computed(() => {
    return props?.fields?.find(field => field.key === 'localCurrency');
  });

  const countryField = computed(() => props?.fields?.find(field => field.key === 'country'));

  const fiatCurrencies = computed(() => localCurrencyField.value?.options || []);

  const countries = computed(() => {
    const list = countryField.value?.options || [];

    return list.map(item => ({
      ...item,
      icon: `${flagUrl.value}/${item.id.toLowerCase()}.svg`,
    }));
  });

  const countryName = computed(() => {
    const country = countries.value.find(item => item.id === currentCountry.value);
    return country?.name || '';
  });

  const isDisabledDepositBtn = computed(
    () => isLoading.value || !amountValue.value || !currentProvider.value || limitError.value
  );

  const providerOffer = computed(() => {
    const paymentMethodOffer = currentProvider.value?.paymentMethodOffer?.find(
      item => item.methodName === currentMethod.value
    );

    if (paymentMethodOffer) {
      const numFee = Number.parseFloat(paymentMethodOffer.fee).toFixed(2);

      return {
        method: paymentMethodOffer.method,
        fee: numFee,
        amountExpected: paymentMethodOffer.amountExpectedTo,
        depositAmount: currentProvider.value?.amountFrom,
      };
    }

    return null;
  });

  const fiatDepositInfo = computed(() => ({
    fiatDeposit: {
      amount: providerOffer.value?.depositAmount ?? '',
      currency: currentFiatCurrency.value,
      cryptoCurrency: selectedCurrency.value,
      fee: providerOffer.value?.fee ?? '',
      amountExpected: providerOffer.value?.amountExpected ?? '',
    },
  }));

  const amountExpected = computed((): string | null => {
    if (!providerOffer.value) {
      return null;
    }

    return providerOffer.value.amountExpected;
  });

  const fieldHint = computed(() => {
    if (limitError.value) {
      return {
        message: getContent(limitsContent.value, defaultLimitsContent.value, 'deposit.wallet.title'),
        variant: 'error',
      };
    }

    return {
      message: `${getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minSum') || ''} ${props.amountMin} ${currentFiatCurrency.value}`,
    };
  });

  const currentProviders = computed(() => {
    return allProviderList.value.filter(item => {
      return item.paymentMethodOffer?.find(method => method.methodName === currentMethod.value);
    });
  });

  const setMethod = (name: string) => {
    currentMethod.value = name;
    currentProvider.value = null;
  };

  const getFee = (provider: IProvider) => {
    const paymentMethodOffer = provider.paymentMethodOffer.find(item => item.methodName === currentMethod.value);

    if (paymentMethodOffer?.fee) {
      return Number.parseFloat(paymentMethodOffer?.fee ?? '0').toFixed(2);
    }

    return 0;
  };

  const createMethodNames = () => {
    const set = new Set();

    allProviderList.value.forEach(item => {
      item.paymentMethodOffer?.forEach(offer => {
        set.add(offer.methodName);
      });
    });

    const methods = Object.values(MethodKey);

    methodKeys.value = methods.filter(item => set.has(item));
  };

  const setProvider = (provider: IProvider) => {
    currentProvider.value = provider;
  };

  const getMethodIconName = (key: string) => {
    switch (key) {
      case MethodKey.VISA:
        return 'visa';
      case MethodKey.APPLE:
        return 'apple-pay';
      case MethodKey.SEPA:
        return 'sepa';
      case MethodKey.IDEAL:
        return 'ideal';
      default:
        return key;
    }
  };

  const onClickDeposit = async () => {
    try {
      isLoading.value = true;
      const {
        data: { redirectUrl },
      } = await useApiAuthInstance('/api/payment/changelly/invoice/deposit', {
        method: 'POST',
        body: {
          provider: currentProvider.value?.providerCode,
          amount: amountValue.value,
          paymentMethod: providerOffer.value?.method,
          currency: selectedCurrency.value,
          localCurrency: currentFiatCurrency.value,
        },
      });

      if (
        !profileStore.isTelegramUser ||
        (!profileStore.isTelegramUser && currentProvider.value?.providerCode === 'moonpay')
      ) {
        redirectProviderUrl.value = redirectUrl;
        showConfirmation.value = true;
      } else {
        onCloseWallet();
        await navigateTo({
          path: '/redirect',
          query: {
            url: redirectUrl,
          },
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
      console.log('Error', e);
    } finally {
      isLoading.value = false;
    }
  };

  const confirmDeposit = async () => {
    await navigateTo('/');
    emit('clickBackBtn');
    onCloseWallet();
  };

  const onCloseWallet = (): void => {
    closeModalSync(ModalName.WALLET);
  };

  const toggleBottomSheetBalanceSelector = (methodName: 'open' | 'close') => {
    cryptoSelector.value?.[methodName]?.();
    isOpenBalanceSelector.value = methodName === 'open';
    searchCurrencyField.value = '';
  };

  const toggleFiatSelector = (methodName: 'open' | 'close'): void => {
    fiatSelector.value[methodName]();
    searchFiatCurrencyField.value = '';
  };

  const toggleBottomSheetCountrySelector = (methodName: 'open' | 'close'): void => {
    countrySelector.value[methodName]();
    searchCountryField.value = '';
  };

  const getProviders = async () => {
    if (!selectedCurrency.value || !currentFiatCurrency.value || !amountValue.value || !currentCountry.value) return;

    if (
      (props.amountMin && amountValue.value < props.amountMin) ||
      (props.amountMax && amountValue.value > props.amountMax)
    )
      return;

    try {
      isLoading.value = true;
      isProvidersLoading.value = true;
      const { data } = await useApiAuthInstance(
        `/api/payment/changelly/providers?currency=${selectedCurrency.value}&localCurrency=${currentFiatCurrency.value}&amount=${amountValue.value}&country=${currentCountry.value}`
      );

      if (data) {
        if (
          (props.amountMin && amountValue.value < props.amountMin) ||
          (props.amountMax && amountValue.value > props.amountMax)
        ) {
          allProviderList.value = [];
          isLoading.value = false;
          return;
        }
        allProviderList.value = data;
      }
    } catch (e) {
      Sentry.captureException(e);
      allProviderList.value = [];
      console.log('Error', e);
    }
    isLoading.value = false;
    isProvidersLoading.value = false;
  };

  const debounceGetProviders = debounce(
    async () => {
      currentProvider.value = null;
      allProviderList.value = [];
      await getProviders();
      createMethodNames();
    },
    500,
    { leading: false }
  );

  const selectCurrency = async (accountId: string) => {
    isMobile.value ? toggleBottomSheetBalanceSelector('close') : (isOpenBalanceSelector.value = false);
    const currency = supportedTransactionAccounts.value.find(account => account.id === accountId)?.currency;
    await getDepositMethods(currency);
  };

  watch([selectedCurrency, currentFiatCurrency, amountValue, currentCountry], () => {
    currentProvider.value = null;
    debounceGetProviders();
  });

  onBeforeMount(async () => {
    currentFiatCurrency.value = localCurrencyField.value?.value || '';
    currentCountry.value = countryField.value?.value || '';
    amountValue.value = props.amountMin || 0;
    defaultValue.value = props.amountMin || 0;
  });
</script>

<style src="~/assets/styles/components/form/deposit-crypto-bank.scss" lang="scss" />
