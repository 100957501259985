<template>
  <div class="qr-code-logo">
    <img v-lazy-load  v-if="showQrCode && createdQrCode" :data-src="createdQrCode" alt="qr-code" />
  </div>
</template>

<script setup lang="ts">
  import QRCode from 'qrcode';
  import logoWcBase64 from '@skeleton/consts/data/logoWcBase64';

  const props = defineProps({
    address: {
      type: String,
      required: true,
    },
  });

  const createdQrCode = ref<string>();
  const showQrCode = ref(false);

  const loadImage = (src: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = src;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error) => {
        reject(error);
      };
    });

  const getQrcode = async (dataForQRcode: string, centerImage: string, width: number, cwidth: number) => {
    const canvas = document?.createElement('canvas');

    if (!canvas) {
      return '';
    }

    canvas.width = width;

    try {
      await QRCode.toCanvas(canvas, dataForQRcode, {
        errorCorrectionLevel: 'H',
        margin: 0,
        width,
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
      });

      const logoImg = await loadImage(centerImage);
      const ctx = canvas.getContext('2d');
      const center = (width - cwidth) / 2;
      ctx?.drawImage(logoImg, center, center, cwidth, cwidth);
    } catch (e) {
      return '';
    }

    return canvas.toDataURL('image/png');
  };

  const createQrCode = async () => {
    if (props.address) {
      showQrCode.value = false;
      createdQrCode.value = await getQrcode(props.address, logoWcBase64, 286, 96);
      showQrCode.value = true;
    }
  };

  onMounted(() => {
    createQrCode();
  });

  watch(
    () => props.address,
    () => {
      createQrCode();
    }
  );
</script>

<style src="~/assets/styles/components/atomic/qr-logowc.scss" lang="scss" />
